.section {
    
    &--top-margin {
        margin-top: 100px;
    }

    &--top-margin1 {
        margin-top: 120px;
    }

    &--bg {
        background: url('../img/service-cards/tatoo-remove-4.jpg') no-repeat;
        background-size: cover;
    }

    &--size {
        width: 100%;
    }

    &--pad {
        padding: 70px 40px 50px;
    }

    &--side-pad {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media(min-width: 768px) {
        &--pad {
            padding: 160px 50px 50px;
        }

        &--side-pad {
            padding-left: 30px;
            padding-right: 30px;
        }
    }


    @media(min-width: 992px) {
        &--pad {
            padding: 70px 50px 50px;
        }

        &--side-pad {
            padding-left: 0;
            padding-right: 0;
        }
    }


    @media(min-width: 1200px) {
        &--pad {
            padding: 160px 50px 50px;
        }
    }

    @media(min-width: 1300px) {
        &--top-margin {
            margin-top: 200px;
        }
    }

    @media(min-width: 1500px) {
        &--size {
            width: 80%;
        }
    }
}

.section-inner {

    &__wrapper {
        
        &--pad {
            padding-bottom: 70px;
        }
    
        &--line {
            border-bottom: 1px solid #000000;
        }

        &--flex {
            display: flex;
            flex-wrap: wrap;
        }

        @media(min-width: 1800px) {
            &--width {
                width: 80%;
            }
        }
    }
}

.section-duration {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;


    &__img {
        position: relative;
        order: 3;
        margin-top: 40px;

        &--size {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
        @media(min-width: 768px) {
            order: 0;
            margin-top: 0;

            &--size {
                width: 47%;
            }
        }

        @media(min-width: 992px) {
            &--size {
                width: 50%;
            }
        }

        @media(min-width: 1200px) {
            &--size {
                width: 41%;
            }

            &--position {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media(min-width: 1400px) {
            &--size {
                width: 48%;
            }
        }
    }

    &__text {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        height: fit-content;
        
        &.section-duration__text--pos1,
        &.section-duration__text--pos2,
        &.section-duration__text--pos3,
        &.section-duration__text--pos4,
        &.section-duration__text--pos5,
        &.section-duration__text--pos6,
        &.section-duration__text--pos7,
        &.section-duration__text--pos8,
        &.section-duration__text--pos9 {
            &:first-child {
                width: 100%;
                order: 0;
                // transform: translate(0, 47px);
            }
    
            &:last-child {
                order: 2;
                width: 100%;
                margin-top: 40px;
                // transform: translate(0, 300px);

                span:nth-child(2) {
                    width: 30%;
                }
                span:last-child {
                    width: 68%;
                    margin-left: 2%;
                }
            }
        }
        &.section-duration__text--pos8,
        &.section-duration__text--pos9 {
            
            span:nth-child(2) {
                width: 53%;
            }

            span:last-child {
                width: 38%;
                margin-left: 2%;
            }
        }

        span:first-child {
            width: 100%;
            font-family: 'Circe-Light';
            font-weight: 300;
            font-size: 65px;
            line-height: 1;
        }

        span:nth-child(2),
        span:last-child {
            font-family: 'Circe-Regular';
            font-weight: 400;
        }

        span:nth-child(2) {
            width: 28%;
            font-size: 35px;
            line-height: 1.2;
        }
        span:last-child {
            width: 70%;
            font-size: 16px;
            line-height: 1.2;
            margin-left: 2%;
        }
        

        @media(min-width: 768px) {
            &.section-duration__text--pos1 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 44px);    
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-24px, -65px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos2 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 44px);    
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-24px, -65px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos3 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 44px);    
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-24px, -65px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }
            
            &.section-duration__text--pos4 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 34px);   
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-24px, -65px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos5 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 34px);   
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-24px, -80px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos6 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 34px);   
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-8px, -101px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos7 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 34px);   
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-8px, -101px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos8 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 34px);
                    
                    span:nth-child(2) {
                        width: 54%;
                    }

                    span:last-child {
                        width: 44%;
                        margin-left: 2%;
                    }
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-8px, -101px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos9 {
                &:first-child {                    
                    align-self: flex-end;
                    width: 40%;
                    transform: translate(-54px, 34px);
                    
                    span:nth-child(2) {
                        width: 54%;
                    }

                    span:last-child {
                        width: 44%;
                        margin-left: 2%;
                    }
                }
        
                &:last-child {
                    order: 0;
                    align-self: flex-end;
                    width: 45%;
                    transform: translate(-8px, -101px);
                    margin-top: 0;

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }
            span:nth-child(2) {
                font-size: 32px;
            }
        }

        @media(min-width: 1200px) {
            &.section-duration__text--pos1 {
                &:first-child {
                    transform: translate(0, 0);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -35px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos2 {
                &:first-child {
                    transform: translate(0, 85px);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -35px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos3 {
                &:first-child {
                    transform: translate(0, 120px);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -35px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }
            
            &.section-duration__text--pos4 {
                &:first-child {
                    transform: translate(0, 0);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -35px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos5 {
                &:first-child {
                    transform: translate(0, 0);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -35px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos6 {
                &:first-child {
                    transform: translate(0, 0);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -60px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos7 {
                &:first-child {
                    transform: translate(0, 0);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -60px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos8 {
                &:first-child {
                    transform: translate(0, 80px);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -60px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }                
            }

            &.section-duration__text--pos9 {
                &:first-child {
                    transform: translate(0, 60px);
                    align-self: initial;
                    width: 30%;
                }
        
                &:last-child {
                    // align-self: flex-end;
                    width: 32%;
                    transform: translate(0px, -60px);

                    span:nth-child(2) {
                        width: 35%;
                    }

                    span:last-child {
                        width: 63%;
                        margin-left: 2%;
                    }
                }                
            }
            
        }

        @media(min-width: 1400px) {
            &.section-duration__text--pos1 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, 47px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 258px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos2 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, 47px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 414px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos3 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, 47px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 414px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos4 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, -17px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 258px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos5 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, -17px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 258px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos6 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, -17px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 220px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos7 {

                &:first-child {
                    width: 20%;
                    transform: translate(0, -17px);
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 220px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos8 {

                &:first-child {
                    width: 25%;
                    transform: translate(0, -17px);

                    span:nth-child(2) {
                        width: 52%;
                    }

                    // span:last-child {
                    //     width: 44%;
                    //     margin-left: 2%;
                    // }
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 373px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos9 {

                &:first-child {
                    width: 25%;
                    transform: translate(0, -17px);

                    span:nth-child(2) {
                        width: 52%;
                    }
                }
        
                &:last-child {
                    align-self: initial;
                    width: 27%;
                    transform: translate(0, 373px);

                    span:nth-child(2) {
                        width: 33%;
                    }

                    span:last-child {
                        width: 65%;
                        margin-left: 2%;
                    }
                }
            }

            span:first-child {
                font-size: 78px;
            }
            span:nth-child(2) {
                font-size: 32px;
            }
        }

        @media(min-width: 1700px) {
            &.section-duration__text--pos1 {
                &:first-child {
                    width: 20%;
                    transform: translate(0, 47px);

                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 300px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos2 {
                &:first-child {
                    width: 20%;
                    transform: translate(23px, 64px);
                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(-60px, 560px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos3 {
                &:first-child {
                    width: 20%;
                    transform: translate(23px, 64px);
                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(-60px, 560px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }
            
            &.section-duration__text--pos4 {
                &:first-child {
                    width: 20%;
                    transform: translate(0, -39px);

                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 300px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos5 {
                &:first-child {
                    width: 20%;
                    transform: translate(0, -15px);

                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 262px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos6 {
                &:first-child {
                    width: 20%;
                    transform: translate(0, -15px);

                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 220px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos7 {
                &:first-child {
                    width: 20%;
                    transform: translate(0, -15px);

                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 220px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos8 {
                &:first-child {
                    width: 23%;
                    transform: translate(0, 45px);
                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 455px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }

            &.section-duration__text--pos9 {
                &:first-child {
                    width: 23%;
                    transform: translate(0, 18px);
                }
        
                &:last-child {
                    width: 25%;
                    transform: translate(0, 455px);

                    span:nth-child(2) {
                        width: 28%;
                    }

                    span:last-child {
                        width: 70%;
                        margin-left: 2%;
                    }
                }
            }
            span:first-child {
                font-size: 100px;
            }
        }
    }

    &--margin {
        margin-top: 140px;
    }

    &--bottom-line {
        padding-bottom: 170px;
        border-bottom: 1px solid #000000;
    }

    @media(min-width: 500px) {
        width: 75%;
    }

    @media(min-width: 576px) {
        width: 60%;
    }

    @media(min-width: 768px) {
        width: 100%;
        max-width: 700px;
        // margin-left: auto;
        // margin-right: auto;
    }

    @media(min-width: 992px) {
        max-width: 652px;
        padding-left: 0;
        padding-right: 0;
    }

    @media(min-width: 1200px) {
        max-width: 860px;
    }

    @media(min-width: 1400px) {
        flex-direction: row;
        max-width: 1060px;
    }

    @media(min-width: 1700px) {
        max-width: 1300px;
    }
}