.btn {
    position: relative;
    display: block;
    font-family: 'Circe-Regular';
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    transition: all 0.4s ease-out;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
}

.btn__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--row,
    &--insta {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    &--margin {
        margin-top: 15px;
    }

    &--margin-small {
        margin-top: 20px;
    }

    &--margin-bigger {
        margin-top: 10px;
    }

    & .btn:last-child {
        margin-top: 20px;
    }

    &--changed,
    &--full {
        display: flex;
        flex-direction: column;
        width: 100%;

        & .btn:last-child {
            margin-top: 20px;
        }
    }

    &--mobile {
        width: 95%;
        margin-right: auto;
        margin-left: auto;
    }

    @media(min-width: 576px) {
        width: 70%;
        &--changed {
            width: fit-content;
        }
        &--mobile {
            display: none;
        }
        
        &--full {
            width: 100%;
        }
    }

    @media(min-width: 768px) {
        width: 47%;

        &--row {
            width: 70%;
        }
    }

    @media(min-width: 992px) {
        width: auto;

        &--insta {
            flex-direction: row;

            button:first-child {
                margin-right: 20px;
            }
        }
    }

    @media(min-width: 1400px) {
        &--row {    
            flex-direction: row;

            button:first-child {
                margin-right: 20px;
            }
        }

    }

    @media(min-width: 1500px) {
        &--margin {
            margin-top: 40px;
        }
    }

    @media(min-width: 1600px) {
        &--margin-bigger {
            margin-top: 30px;
        }
    }

}

.btn--center {
    margin-left: auto;
    margin-right: auto;
}

.btn--size {
    width: auto;
    padding: 0.71em 2.5em;

    @media(min-width: 992px) {
        width: fit-content;
    }
}

.btn--size-header {
    width: auto;
    padding: 0.71em 2.5em;

    @media(min-width: 1400px) {
        width: fit-content;
    }
}

.btn--size-narrow {
    width: 94%;
    padding: 0.71em 2.5em;
}

.btn--size-form {
    width: 100%;
    padding: 0.71em 2.5em;
}

.btn--size-small {
    font-size: 11px;
    line-height: 1.5;

    @media(min-width: 576px) {
        font-size: 14px;
        line-height: 21px;
    }
}


.btn--light {
    color: #000000;
    background: #ffffff;
    border: 1px solid #000000;

    &:hover,
    &:focus,
    &:active {
        outline: none;
        background: #000000;
        border-style: outset;
        color: #ffffff;
    }
}

.btn--dark {
    color: #ffffff;
    background: #000000;
    border: 1px solid #ffffff;

    &:hover,
    &:focus,
    &:active {
        outline: #000000 solid 1px;
        border-style: outset;
    }
}


.btn--mt {
    margin-top: 40px;
}

.btn--mt20 {
    margin-top: 20px;
}

.btn--d-none {

    @media(min-width: 992px) {
        display: none;
    }
}

.btn-d-none-small {
    @media(min-width: 576px) {
        display: none;
    }
}