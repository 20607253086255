.comments__wrapper {
    position: relative;
    max-width: 100%;
    width: 100%;

    @media(min-width: 992px) {
        width: calc(100vw - 340px);
    }
}

.comment-card {
    
    width: fit-content;
    background: #F9F9F9;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 35px 20px 35px 30px;
    margin: 0 15px;


    &__text {
        font-family: 'Circe-Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;

        &--dark {
            color: #000;
        }

        &--margin {
            margin-top: 20px;
        }
    }

    &__name {
        font-family: 'Circe-Bold';
        font-weight: 700;
        font-size: 14px;
        line-height: 1.5;
        
        &--margin {
            margin-top: 40px;
        }
    }

    &__date {
        font-family: 'Circe-Bold';
        font-weight: 700;
        font-size: 14px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.6);
    }

    &:not(:first-child) {
        margin-top: 20px;
    }

    @media(min-width: 576px) {
        max-width: 30%;

        &:not(:first-child) {
            margin-top: 0;
        }
    }

    @media(min-width: 1200px) {
        padding: 45px 30px 45px 42px;
    }

}