.block-about {
    display: flex;
    flex-wrap: wrap;

    &__photo {
        height: fit-content;
    }

    &__description {
        // min-width: 700px;
        background: #F9F9F9;
        padding: 25px 15px 64px 15px; 
        height: fit-content;
        text-align: left;

        &>.text:not(:first-of-type) {
            margin-top: 35px;
        }

        &>.text:nth-of-type(2) {
            margin-top: 55px;
        }

        &--pad {
            padding: 45px 35px 60px 35px;
        }
        &--pad1 {
            padding: 50px 30px 50px 30px;
        }

        &--pad-smaller {
            padding: 45px 35px 60px 35px;
        }
    }

    &--margin {
        margin-top: 90px;
    }

    &--margin1 {
        margin-top: 90px;
        margin-bottom: 100px;
    }

    &--margin-small {
        margin-top: 90px;
    }

    @media(min-width: 576px) {
        
        &__description {
            max-width: 80%;
            padding: 64px 45px 64px 50px;

            &--pad {
                padding: 65px 45px 60px 50px;
            }

            &--pad1 {
                padding: 50px 30px 50px 30px;
            }
        }
        &__photo {
            max-width: 80%;
        }

    }

    @media(min-width: 768px) {
        &__description {
            max-width: 85%;
            transform: translate(15%, -60px);

            &--pad {
                padding: 65px 125px 60px 50px;
            }

            &--pad1 {
                padding: 70px 70px 80px 60px;
            }
        }
        &__photo {
            max-width: 70%;
        }

        &--margin {
            margin-top: 210px;
            margin-bottom: 100px;
        }

        &--margin1 {
            margin-top: 210px;
            margin-bottom: 0;
        }
    }

    @media(min-width: 992px) {
        &__description {

            &--pad {
                padding: 65px 85px 60px 50px;
            }

            &--pad1 {
                padding: 70px 70px 80px 60px;
            }
        }
        &--margin1 {
            margin-bottom: 100px;
        }

    }

    @media(min-width: 1200px) {
        &__description {
            max-width: 70%;
            transform: translate(30%, -60px);
        }
        &__photo {
            max-width: 60%;
        }

        &--margin {
            margin-top: 210px;
            margin-bottom: 310px;
        }
    }

    @media(min-width: 1300px) {
        &__description {

            &--pad {
                padding: 80px 150px 60px 50px;
            }

            &--pad1 {
                padding: 70px 90px 90px 60px;
            }
        }

    }

    @media(min-width: 1540px) {
        flex-wrap: nowrap;

        &__description {
            max-width: 50%;
            transform: translate(-50px, 80px);
        }
        &__photo {
            max-width: 50%;
        }

        &__description {
            padding: 64px 85px 64px 50px;

            &--pad {
                padding: 80px 150px 60px 50px;
            }

            &--pad1 {
                padding: 80px 90px 90px 60px;
            }

            &--pad-smaller {
                padding: 80px 50px 60px 50px;
            }
        }

    }

    @media(min-width: 1600px) {
        &--margin1 {
            margin-bottom: 150px;
        }
    }

    @media(min-width: 1740px) {

        &__description {
            max-width: 45%;
        }
    }
}

