.form-consultation,
.block-about__description {
    text-align: center;

    &--size {
        width: 94%;
        margin-left: auto;
        margin-right: auto;
    }

    &--align {
        text-align: left;
    }

    &--margin {
        margin-top: 110px;
    }    

    form {
        margin-top: 45px;
        
        input,
        select {
            width: 100%;
            background: #F9F9F9;
            border: 0.5px solid rgba(0, 0, 0, 0.2);
            padding: 15px;
            text-align: center;            
            margin-bottom: 20px;
            transition: border 0.3s linear;

            &:hover,
            &:focus,
            &:active {
                outline: none;
                border: 0.5px solid rgba(0, 0, 0, 0.8);
            }
        }

        select {
            position: relative;
            color: rgba(0, 0, 0, 0.6);
            appearance: none;
            text-align-last: center;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: url(../img/icons/arrow-black.svg);
                background-position: 100% 50%;
                z-index: 2;
            }
        }

        select:active,
        select:focus {
            color: rgba(0, 0, 0, 1);
        }
    }

    &--form-changed {

        form input,
        form select {
            background: #fff;
            text-align: left;
            text-align-last: left;
        }
    }

    @media(min-width: 576px) {
        &--size {
            width: 500px;
        }
    }

    @media(min-width: 768px) {
        &--margin {
            margin-top: 150px;
        }
    }

    @media(min-width: 1400px) {
        &--margin {
            margin-top: 290px;
        }
    }
}

.select-wrapper {

}