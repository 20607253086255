.nav-aside {    
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    height: 100vh;
    background: #ffffff;
    z-index: -1;
    transition: width 0.8s ease;

    &__wrapper {
        max-height: 100vh;        
        overflow-y: scroll;
        &--customized-scroll::-webkit-scrollbar {
            width: 9px;
            background-color: #f2f2f2;
        }

        &--customized-scroll::-webkit-scrollbar-thumb {
            background: #aaaaaa;
        }

        & .nav__item:hover,
        & .nav__item:focus {
            .nav-sub__list {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &--pad {
        opacity: 0;
        transition: opacity 0.4s ease-in 0.4s;
    }

    &--open {        
        width: 100%;
        opacity: 1;
        z-index: 3;

        .nav-aside--pad {
            opacity: 1;
            padding: 12px 60px 60px 50px;  
        }
    }

    @media(min-width: 992px) {
        opacity: 1;
        position: relative;
        min-width: 340px;
        width: 340px;
        height: 100vh;
        background: rgb(255, 255, 255);
        z-index: 3;

        &--pad {
            opacity: 1;
        }
    }
}

@media(min-width: 992px) {
    .nav-aside .nav-aside__wrapper {
        position: fixed;
        z-index: 2;
        width: inherit;
        max-height: 100vh;
        overflow-y: visible;
    }
    .nav-aside--pad {
        padding: 12px 60px 60px 70px;
    }
}


.nav__list {
    position: relative;
    // position: fixed;
    list-style-type: none;
    padding-left: 0;

    &--bottom-line {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 32px;
            height: 1.5px;
            background: @color-standart;
        }
    }

    &--pad {
        padding: 10px 0;
    }
}


.nav__item {
    position: relative;

    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.nav__link {
    text-decoration: none;
    font-family: "Circe-Regular";
    font-size: @font-standart;
    line-height: 1.2;
    color: black;    
}

.nav-toggle {
	z-index: 5;
	
	.ham {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: transform 400ms;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
	}

	.hamRotate.active {
		position: fixed;
		transform: rotate(45deg);
		right: 2%;
        top: 2%;
		
		.line {
			stroke: #000000;
		}
    }

	.line {
        fill:none;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke:#ffffff;
        stroke-width:3.5;
        stroke-linecap:round;
	}

	.ham4 .top {
	  stroke-dasharray: 40 121;
	}

	.ham4 .bottom {
	  stroke-dasharray: 40 121;
	}

	.ham4.active .top {
	  stroke-dashoffset: -68px;
	}

	.ham4.active .bottom {
	  stroke-dashoffset: -68px;
	}
    
}

.nav-mobile {
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 30px;
    z-index: 5;
    // background: #ffffff;

    &--d-none {
        display: flex;
    }

    &--bg-white {
        background: #ffffff;
    }

    @media(min-width: 992px) {
        &--d-none {
            display: none;
        }
    }
}

body.touch,
body.mouse {
    .nav-sub__list {
        display: none;
        margin-top: 15px;
        padding-left: 15px;
        transition: all 0.4s ease-out;

        &--open {
            display: block;
        }
    }
}

body.mouse {

    @media(min-width: 992px) {
        .nav-sub__list {
            display: block;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            width: 200%;
            height: 100vh;
            right: -200%;
            top: 0;
            background: #fff;
            padding-left: 60px;
            padding-right: 20px;
            transition: all 0.4s ease-out;
        
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1000%;
                top: -1000%;
                left: 0;
                background: inherit;
            }
        }
        
        .nav-aside {
            &__wrapper {
                & .nav__item:hover,
                & .nav__item:focus {
                    .nav-sub__list {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.plus {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: transparent;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.3s linear;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background: #000;
        transition: all 0.3s linear;
    }

    &:before {        
        top: 0;
        left: 50%;
        transform: translateX(-50%) rotate(0deg);
        width: 1px;
        height: 100%;        
    }

    &:after {        
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;        
    }

    &.active {
        &:before {
            transform: translateX(-50%) rotate(90deg);
        }
    }
}

.menu-inner {

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &--pad {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    &__block {
        width: 100%;

        &:nth-child(n+2) {
            margin-top: 40px;
        }
    }

    &--margin {
        margin-top: 80px;
    }

    @media(min-width: 576px) {

        &__block {
            width: 42%;

            &:nth-child(n+2) {
                margin-top: 0;
            }
    
            &:nth-child(n+3) {
                margin-top: 40px;
            }
        }
    }

    @media(min-width: 768px) {

        &__wrapper {
            justify-content: flex-start;
        }
        &__block {
            width: 38%;

            &--margin-right:not(:last-child) {
                margin-right: 12%;
            }
        }

        &--margin {
            margin-top: 100px;
        }
    }
    
    @media(min-width: 992px) {
        &__wrapper {
    
            &--pad {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &--margin {
            margin-top: 140px;
        }
    }

    @media(min-width: 1300px) {

        &__block {
            width: 30%;
    
            &--margin-right:not(:last-child) {
                margin-right: 10%;
            }
        }
    }

    @media(min-width: 1400px) {

        &__block {
            width: 25%;
    
            &--margin-right:not(:last-child) {
                margin-right: 10%;
            }

            &:nth-child(n+3) {
                margin-top: 0;
            }
        }
    }

    @media(min-width: 1700px) {

        &__block {
            width: 20%;
    
            &--margin-right:not(:last-child) {
                margin-right: 170px;
            }
        }
    }
}