.service__wrapper,
.sertificates__wrapper,
.masters__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @media(min-width: 768px) {
        justify-content: flex-end;
    }
}

.service-card {
    position: relative;
    // width: 50%;
    // transition: all 0.4s linear;

    button {
        opacity: 0;
        transition: opacity 0.4s linear;
    }

    &--width-third {
        width: 50%;

        .aspect-ratio-box {
            padding-top: 351px / 390px * 100%;
        }
    }

    &--width-half {
        width: 50%;

        .aspect-ratio-box {
            padding-top: 585px / 792px * 100%;
        }

        .service-card__bg {
            object-position: 50% 15%;
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        filter: grayscale(1) brightness(44%);
    }

    &__title {
        position: relative;
        font-family: 'Circe-Light';
        font-weight: 300;
        font-size: 16px;
        line-height: 1.2;
        transform: translateY(100%);
        transition: transform 0.4s linear;

        &--light {
            color: #FFFFFF;
        }
    }

    &--pad {
        // padding: 280px 25px 104px;
    }

    &:hover,
    &:focus,
    &:active {
        .service-card__bg {
            animation: changeCardColor 0.4s linear forwards; 
        }
        button {
            opacity: 1;
        }
        .service-card__title {
            transform: translateY(0);
        }
    }

    .aspect-ratio-box {
        overflow: hidden;        
        position: relative;
    }
    
    .aspect-ratio-box-inside {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 25px 25px;
    
        @media(min-width: 1200px) {
    
        }
    
        @media(min-width: 1420px) {
            padding: 40px 25px;
        }
    }

    @media(min-width: 476px) {

        &__title {
            font-size: 19px;
        }
    }

    @media(min-width: 576px) {

        &__title {
            font-size: 24px;
            transform: translateY(60%);
        }
    }

    @media(min-width: 768px) {

        &__title {
            font-size: 30px;
        }
    }

    @media(min-width: 992px) {
        // width: 45%;

        &--width-third {
            width: 45%;
        }

        &__title {
            font-size: 24px;
        }
    }

    @media(min-width: 1200px) {
        // width: 33.3333%;

        &--width-third {
            width: 33.3333%;
        }
    
        &__title {
            font-size: 24px;
        }
    }

    @media(min-width: 1420px) {
        &__title {
            font-size: 32px;
        }
    }

    @media(min-width: 1520px) {
        &__title {
            font-size: 36px;
        }
    }

    @media(min-width: 1650px) {
        &__title {
            font-size: 40px;
        }
    }
}

@keyframes changeCardColor {
    0% {
        filter: grayscale(1) brightness(44%);
    }
    45% {
        filter: grayscale(0.65) brightness(65%);
    }
    75% {
        filter: grayscale(0.35) brightness(85%);
    }
    100% {
        filter: grayscale(0) brightness(100%);
    }

}