.list {    
    list-style-type: none;
    padding-left: 0;

    &--flex {
        display: flex;
        flex-wrap: wrap;
    }

    &--bottom-line {
        border-bottom: 1px solid #000000;
    }

    &--advantages {
        border-bottom: 1px solid #000000;
        padding-left: 30px;

        &--margin {
            margin-top: 100px;
        }

        li {
            width: 100%;
            padding-right: 30px;
            padding-bottom: 50px;            
        }

        img {
            width: 50px;
            height: 55px;
            margin-bottom: 15px;
        }

        @media(min-width: 576px) {
            padding-left: 40px;
            li {
                width: 50%;
                padding-right: 40px;          
            }
        }

        @media(min-width: 768px) {
            li {
                width: 43%;            
            }

            img {
                width: 60px;
                height: 65px;
            }
        }

        @media(min-width: 992px) {
            padding-left: 0;
        }

        @media(min-width: 1200px) {           
            li {
                width: 33%;
            }
        }

        @media(min-width: 1400px) {           
            li {
                width: 30%;
            }
        }

        @media(min-width: 1500px) { 
            border-bottom: none;
            
            li {
                width: 25%;
            }

            li:nth-child(n+5) {
                border-bottom: 1px solid #000000;
            }
        }

        @media(min-width: 1600px) {
            li {
                width: 22%;
            }
        }
        
    }

    &--line {
        flex-direction: column;
        margin-left: 30px;
    }

    &--font-bigger {
        padding-left: 30px;
        font-family: 'Circe-Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;

        @media(min-width: 576px) {
            font-size: 19px;
        }

        @media(min-width: 768px) {
            font-size: 21px;
        }
    }

    &--font-light {
        padding-left: 30px;
        font-family: 'Circe-Light';
        font-weight: 300;
        font-size: 16px;
        line-height: 1.8;

        @media(min-width: 576px) {
            font-size: 19px;
        }

        @media(min-width: 768px) {
            font-size: 21px;
        }
    }

    &--margin {
        margin-top: 40px;
    }

    &__item {
        position: relative;

        &--margin {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        &--margin1 {
            &:not(:last-child) {
                margin-right: 80px;
            }

            &:last-child {
                margin-right: 30px;
            }
        }

        &--margin2 {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        &:before {
            content: "";
            position: absolute;
            left: -30px;
            width: 14px;
            height: 100%;
        }

        &--check {

            &:before {
                background: url(../img/icons/check.svg) no-repeat center;
                background-size: contain;
            }
        }
        &--cross {

            &:before {
                background: url(../img/icons/cross.svg) no-repeat;
                background-size: contain;
            }
        }
    }

    &--service-descr {
        padding-left: 30px;
        font-family: 'Circe-Light';
        font-weight: 300;
        font-size: 18px;
        line-height: 2.16;

        .list__item {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: -30px;
                width: 14px;
                height: 100%;
                background: url(../img/icons/check.svg) no-repeat center;
                background-size: contain;
            }
        }
    }

    &--materials-descr {
        padding-left: 30px;
        font-family: 'Circe-Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 2;

        .list__item {
            position: relative;

            &--flex-item {
                width: 100%;
            }            

            &:before {
                content: "";
                position: absolute;
                left: -30px;
                width: 14px;
                height: 100%;
            }

            &--check {

                &:before {
                    background: url(../img/icons/check.svg) no-repeat center;
                    background-size: contain;
                }
            }
            &--cross {

                &:before {
                    background: url(../img/icons/cross.svg) no-repeat center;
                    background-size: contain;
                }
            }

            @media(min-width: 576px) {
                &--flex-item {
                    width: 50%;
                }    
            }
        }
    }

    @media(min-width: 576px) {

        &--height {
            height: 4em;
        }

        &--service-descr {
            font-size: 22px;
        }

        .list__item {

            &:before {
                width: 16px;
            }
        }
    }

    @media(min-width: 992px) {
        &--line {
            margin-left: 0;
        }
    }

    @media(min-width: 1200px) {
        &--line {
            flex-direction: row;
        }
    }

    @media(min-width: 1500px) {
        &--bottom-line {
            border-bottom: 1px solid #000000;
        }
    }

    @media(min-width: 1700px) {
        &--service-descr {
            font-size: 24px;
        }
    }
}

.list-services {

    .list__item:not(:last-child) {
        margin-bottom: 20px;
    }

    .list__item {
        font-size: 17px;
        a {
            color: inherit;
        }
    }

    @media(min-width: 768px) {
        .list__item {
            font-size: 19px;
        }
    }

    @media(min-width: 992px) {
        .list__item {
            font-size: 20px;
        }
    }

    @media(min-width: 1300px) {
        .list__item {
            font-size: 21px;
        }
    }
}