.header {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 1px 35px 20px 15px;
    display: flex;
    align-items: flex-end;
}

@media(min-width: 576px) {
    .header {
        padding: 1px 85px 60px 50px;
    }
}

.header__slider,
.header__img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .slick-list,
    .slick-track {
        height: 100%;
    }
}
.header__img {   
    .img-stopper {
        // object-position: center right;
    } 

    .img-stopper--changed {
        object-position: 0% 100%;
    }

    .img-stopper--pos1 {
        object-position: 80% 0%;
    }

    @media(min-width: 1200px) {
        .img-stopper {
            object-position: 60% 0;
        }
    }

    // @media(min-width: 1400px) {
    //     .img-stopper {
    //         object-position: center right;
    //     }
    // }
}

.header__title {
    position: relative;
    height: max-content;
    z-index: 1;
    margin-top: 90px;
}

.header__title--width {
    width: 100%;

    @media(min-width: 450px) {
        width: 80%;
    }

    @media(min-width: 1050px) {
        width: 50%;
    }
}

.header__title--width-wider {
    width: 100%;

    @media(min-width: 450px) {
        width: 98%;
    }

    @media(min-width: 1700px) {
        width: 80%;
    }
}