.section-makeup__wrapper {
    width: 100%;

    &--colored {
        background: #F9F9F9;
    }

    &--pad {
        padding: 65px 0 50px 0;
    }    

    &--margin {
        margin-top: 80px;
    }

    @media(min-width: 768px) {
        &--pad {
            padding: 85px 0 80px 45px;
        }
    }

    @media(min-width: 1200px) {
        width: 80%;

        &--margin {
            margin-top: 120px;
        }
    }

    @media(min-width: 1300px) {
        width: 70%;
    }

    @media(min-width: 1400px) {
        &--margin {
            margin-top: 170px;
        }
    }

    @media(min-width: 1500px) {
        display: flex;
        width: 100%;
    }

    @media(min-width: 1600px) {

        &--pad {
            padding: 110px 0 95px 60px;
        }
    }
}

.section-materials {

    &__wrapper {
        width: 100%;

        &--margin {
            margin-top: 110px;
        }
    }

    &__descr {
        width: 100%;
    }
    

    &__img {
        width: 100%;

        &--size {
            width: 40%;
        }

        &--position {
            img {
                object-position: 10%;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--pad {
        padding: 15px 35px;
    }

    &--pad1 {
        padding: 90px 50px;
    }

    @media(min-width: 576px) {

    }

    @media(min-width: 768px) {
        &__descr,
        &__img {
            width: 80%;
        }
    }

    @media(min-width: 992px) {
        &__descr,
        &__img {
            width: 100%;
        }

        &--pad {
            padding: 15px 55px;
        }
    }

    @media(min-width: 1200px) {
        &__descr,
        &__img {
            width: 80%;
        }
    }

    @media(min-width: 1300px) {
        &__wrapper {    
            &--margin {
                margin-top: 210px;
            }
        }
        &__descr,
        &__img {
            width: 70%;
        }
    }

    @media(min-width: 1400px) {
        &__wrapper {
            display: flex;
        }
        &__descr {
            width: 60%;
        }
        &__img {
            width: 40%;
        }
    }

    @media(min-width: 1500px) {
        &--pad {
            padding: 30px 90px;
        }
    }

    @media(min-width: 1600px) {
        &__img,
        &__descr {
            width: 50%;
        }
    }
}

.section-makeup {

    &__description {
        padding-left: 30px;        
    }

    &__text {
        width: 100%;
        list-style-type: none;
        counter-reset: section;        
        padding-right: 30px;
        padding-left: 30px;

        li {
            position: relative;
            
            &:before {
                counter-increment: section;
                content: counter(section);
                position: absolute;
                top: 0;
                left: -30px;
                font-family: 'Circe-Bold';
                font-weight: 700;
                font-size: 32px;
                line-height: 1;
            }

            &:not(:first-child) {
                margin-top: 25px;
            }
        }

        &--margin {
           margin-top: 80px;
        }

        @media(min-width: 576px) {
            li {    
                &:before {
                    font-size: 36px;
                }
    
                &:not(:first-child) {
                    margin-top: 15px;
                }
            }
        }

        @media(min-width: 1600px) {
            padding-right: 60px;
        }

        @media(min-width: 1800px) {
            padding-right: 140px;
        }
    }

    &__advantages {
        padding-left: 70px;
        list-style-type: none;

        &--margin {
            margin-top: 60px;
        }
        
        &--pad {
            padding-right: 30px;
        }

        li:not(:first-child) {
            margin-top: 40px;
        }

        li {
            position: relative;

            img {
                position: absolute;
                width: 40px;
                height: auto;
                left: -70px;
                top: 0;
            }
        }

        &--title {
            font-family: 'Circe-Bold';
            font-weight: 700;
            font-size: 20px;
            line-height: 1.2;
        }

        &--text {
            font-family: 'Circe-Regular';
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
        }

        @media(min-width: 576px) {
            &--pad {
                padding-right: 100px;
            }
        }

        @media(min-width: 768px) {
            padding-left: 80px;

            li {
    
                img {
                    width: 50px;
                    left: -80px;
                }
            }

            &--pad {
                padding-right: 200px;
            }
        }

        @media(min-width: 992px) {
            &--pad {
                padding-right: 50px;
            }
        }

        @media(min-width: 1200px) {
            &--pad {
                padding-right: 90px;
            }
        }

        @media(min-width: 1700px) {
            &--pad {
                padding-right: 130px;
            }
        }
    }

    &__video {
        width: 100%;
        margin-top: 35px; 

        iframe,
        video {
            width: 100%;
        }
    }

    @media(min-width: 576px) {

        &__text {
            columns: 2;
            break-inside: avoid;
            column-gap: 10%;
        }
    }

    @media(min-width: 768px) {
        
        &__description {
            padding-left: 0;
        }

        &__video {
            width: 80%;
        }
    }

    @media(min-width: 992px) {
        &__video {
            width: 100%;
        }
    }

    @media(min-width: 1500px) {
        &__description {
            width: 55%;
        }

        &__video {
            width: 45%;
            margin-top: 15px;

            video {
                height: 100%;
            }
        }
    }

    @media(min-width: 1600px) {
        &__description {
            width: 50%;
        }

        &__video {
            width: 50%;
        }
    }
    
}