.content-container__main {
    width: 100vw;

    .title {
        text-align: center;
    }

    .title--pos-left {
        text-align: left;
    }
}