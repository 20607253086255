.icon-svg {
    fill: #ffffff;
}

.logo-svg {
    .logo-text {
        fill: #ffffff;
    }

    .logo-text--dark {
        fill: #000000;
    }
}

.svg-eyelash__left,
.svg-eye-arrow__left,
.svg-eyelash__right,
.svg-eye-arrow__right,
.svg-arrows-shadow__left,
.svg-arrows-shadow__right,
.svg-microblending__left,
.svg-microblending__right,
.svg-brows-mixed__left,
.svg-brows-mixed__right,
.svg-brows-hair__left,
.svg-brows-hair__right,
.svg-brows-correction__left,
.svg-brows-correction__right,
.svg-lipstick__left,
.svg-lipstick__right,
.svg-lipstick-watercolor__left,
.svg-lipstick-watercolor__right {
    display: none;
}

@media(min-width: 768px) {

    .svg-eyelash__left,
    .svg-eye-arrow__left,
    .svg-eyelash__right,
    .svg-eye-arrow__right,
    .svg-arrows-shadow__left,
    .svg-arrows-shadow__right,
    .svg-microblending__left,
    .svg-microblending__right,
    .svg-brows-mixed__left,
    .svg-brows-mixed__right,
    .svg-brows-hair__left,
    .svg-brows-hair__right,
    .svg-brows-correction__left,
    .svg-brows-correction__right,
    .svg-lipstick__left,
    .svg-lipstick__right,
    .svg-lipstick-watercolor__left,
    .svg-lipstick-watercolor__right {
        display: block;
        position: absolute;
    }

    .svg-eyelash__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: 48px;
        left: 82px;
    }
    .svg-eye-arrow__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: 65px;
        left: 130px;
        height: 110px;
    }
    .svg-arrows-shadow__left {        
        transform: rotateY(180deg);
        width: 259px;
        top: 65px;
        left: 145px;
        height: 140px;
    }
    .svg-microblending__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: -11px;
        left: 105px;
    }
    .svg-brows-mixed__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: -10px;
        left: 115px;
    }
    .svg-brows-hair__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: -10px;
        left: 115px;
    }
    .svg-brows-correction__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: -10px;
        left: 115px;
    }
    .svg-lipstick__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: 50px;
        left: 115px;
    }
    .svg-lipstick-watercolor__left {        
        transform: rotateY(180deg);
        width: 300px;
        top: 50px;
        left: 115px;
    }

    .svg-eyelash__right {
        top: 112px;
        right: -160px; 
    }
    .svg-eye-arrow__right {
        top: 220px;
        right: -76px;
        height: 110px;
        width: 300px;
    }
    .svg-arrows-shadow__right {
        top: 239px;
        right: -76px;
        height: 93px;
        width: 312px;
    }
    .svg-microblending__right {
        top: 112px;
        right: -160px; 
    }
    .svg-brows-mixed__right {
        top: 97px;
        right: -160px; 
    }
    .svg-brows-hair__right {
        top: 70px;
        right: -160px; 
    }
    .svg-brows-correction__right {
        top: 70px;
        right: -160px; 
    }
    .svg-lipstick__right {
        top: 200px;
        right: -150px;
    }
    .svg-lipstick-watercolor__right {
        top: 200px;
        right: -150px;
    }
}

@media(min-width: 1200px) {
    .svg-eyelash__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 115px;
        top: 10px;
        left: -220px;
    }
    .svg-eye-arrow__left {
        transform: rotateY(0deg);
        width: 270px;
        height: 157px;
        top: 36px;
        left: -123px;
    }
    .svg-arrows-shadow__left {
        transform: rotateY(0deg);
        width: 300px;
        height: 157px;
        top: 66px;
        left: -147px;
    }
    .svg-microblending__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 61px;
        top: 10px;
        left: -220px;
    }
    .svg-brows-mixed__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 61px;
        top: 10px;
        left: -220px;
    }
    .svg-brows-hair__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 61px;
        top: 10px;
        left: -220px;
    }
    .svg-brows-correction__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 61px;
        top: 10px;
        left: -220px;
    }
    .svg-lipstick__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 61px;
        top: 90px;
        left: -220px;
    }
    .svg-lipstick-watercolor__left {
        transform: rotateY(0deg);
        width: fit-content;
        height: 61px;
        top: 70px;
        left: -220px;
    }

    .svg-eyelash__right {
        height: 200px;
        top: 123px;
        right: -155px;
    }
    .svg-eye-arrow__right {
        height: 133px;
        top: 240px;
        right: 28px;
        width: 201px;
    }
    .svg-arrows-shadow__right {
        height: 120px;
        top: 259px;
        right: 28px;
        width: 228px;
    }
    .svg-microblending__right {
        height: 178px;
        width: 230px;
        top: 143px;
        right: -155px;
    }
    .svg-brows-mixed__right {
        height: 219px;
        width: 230px;
        top: 102px;
        right: -155px;
    }
    .svg-brows-hair__right {
        height: 210px;
        width: 230px;
        top: 80px;
        right: -139px;
    }
    .svg-brows-correction__right {
        height: 197px;
        width: 230px;
        top: 96px;
        right: -139px;
    }
    .svg-lipstick__right {
        height: auto;
        width: 230px;
        top: 227px;
        right: -76px;
    }
    .svg-lipstick-watercolor__right {
        height: auto;
        width: 230px;
        top: 227px;
        right: -76px;
    }
}

@media(min-width: 1400px) {
    .svg-eyelash__left {
        height: auto;
        width: 240px;
        top: 111px;
        left: -80px;
    }
    .svg-eye-arrow__left {
        height: 165px;
        width: fit-content;
        top: 111px;
        left: -146px;
    }
    .svg-arrows-shadow__left {
        height: auto;
        width: 380px;
        top: 116px;
        left: -164px;
    }
    .svg-microblending__left {
        height: auto;
        width: 240px;
        top: 45px;
        left: -80px;
    }
    .svg-brows-mixed__left {
        height: auto;
        width: 251px;
        top: 45px;
        left: -65px;
    }
    .svg-brows-hair__left {
        height: auto;
        width: 251px;
        top: 45px;
        left: -65px;
    }
    .svg-brows-correction__left {
        height: auto;
        width: 251px;
        top: 45px;
        left: -65px;
    }
    .svg-lipstick__left {
        height: auto;
        width: fit-content;
        top: 132px;
        left: -159px;
    }
    .svg-lipstick-watercolor__left {
        height: auto;
        width: fit-content;
        top: 132px;
        left: -159px;
    }

    .svg-eyelash__right {
        height: 134px;
        width: 173px;
        top: 188px;
        right: -32px;
    }
    .svg-eye-arrow__right {
        height: 134px;
        width: 360px;
        top: 346px;
        right: -15px;
    }
    .svg-arrows-shadow__right {
        height: 110px;
        width: 384px;
        top: 373px;
        right: -15px;
    }
    .svg-microblending__right {
        height: 134px;
        width: 173px;
        top: 188px;
        right: -32px;
    }
    .svg-brows-mixed__right {
        height: 170px;
        width: 173px;
        top: 154px;
        right: -32px;
    }
    .svg-brows-hair__right {
        height: 170px;
        width: 173px;
        top: 118px;
        right: -32px;
    }
    .svg-brows-correction__right {
        height: 170px;
        width: 173px;
        top: 118px;
        right: -32px;
    }
    .svg-lipstick__right {
        height: 170px;
        width: 173px;
        top: 325px;
        right: -5px;
    }
    .svg-lipstick-watercolor__right {
        height: 170px;
        width: 173px;
        top: 325px;
        right: -5px;
    }
}

@media(min-width: 1700px) {
    .svg-eyelash__right {
        width: auto;
        height: auto;
        top: 225px;
        right: -52px;
    }
    .svg-eye-arrow__right {
        width: fit-content;
        height: auto;
        top: 420px;
        right: 35px;
    }
    .svg-arrows-shadow__right {
        width: fit-content;
        height: auto;
        top: 465px;
        right: 35px;
    }
    .svg-microblending__right {
        width: auto;
        height: auto;
        top: 225px;
        right: -52px;
    }
    .svg-brows-mixed__right {
        width: auto;
        height: auto;
        top: 188px;
        right: -52px;
    }
    .svg-brows-hair__right {
        width: auto;
        height: auto;
        top: 147px;
        right: -52px;
    }
    .svg-brows-correction__right {
        width: auto;
        height: auto;
        top: 147px;
        right: -52px;
    }
    .svg-lipstick__right {
        width: fit-content;
        height: auto;
        top: 400px;
        right: -52px;
    }
    .svg-lipstick-watercolor__right {
        width: fit-content;
        height: auto;
        top: 400px;
        right: -52px;
    }

    .svg-eyelash__left {
        width: auto;
        top: 130px;
        left: -140px;
    }
    .svg-eye-arrow__left {
        width: fit-content;
        height: auto;
        top: 146px;
        left: -140px;
    }
    .svg-arrows-shadow__left {
        width: fit-content;
        height: auto;
        top: 146px;
        left: -140px;
    }
    .svg-microblending__left {
        width: auto;
        top: 40px;
        left: -140px;
    }
    .svg-brows-mixed__left {
        width: 320px;
        top: 60px;
        left: -105px;
    }
    .svg-brows-hair__left {
        width: 320px;
        top: 60px;
        left: -105px;
    }
    .svg-brows-correction__left {
        width: 320px;
        top: 60px;
        left: -105px;
    }
    .svg-lipstick__left {
        width: fit-content;
        top: 202px;
        left: -152px;
    }
    .svg-lipstick-watercolor__left {
        width: fit-content;
        top: 168px;
        left: -152px;
    }
}

