.contacts {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: transparent; 
}

.contacts--mob-fixed {
    z-index: 4;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
}

.contacts--sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: #FFFFFF;    
    animation: fixedMenu 1s forwards;
    z-index: 3;

    .contacts__item,
    .contacts__item a {
        color: #000000;
    }

    .icon--side-line::after {
        background: #000000;
    }

    .nav-mobile .nav-toggle .line {
        stroke: #000000;
    }

    .nav-mobile .nav-toggle .hamRotate.active {
        top: 15%;
    }

    .nav-mobile .logo-svg {
        .logo-text {
            fill: #000000;
        }
    }

    .nav-mobile {
        z-index: 2;
    }

    @media(min-width: 992px) {
        z-index: 2;
    }
}

@keyframes fixedMenu {
    0% {        
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.contacts__item {

    margin-right: 60px;

    &:nth-child(1) {
        margin-right: 80px;
    }   
}

.contacts__wrapper {
    display: flex;
    justify-content: right;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 14px 0 24px;
}

.contacts__wrapper--d-none {
    display: none;
}

@media(min-width: 992px) {
    .contacts__wrapper--d-none {
        display: flex;
    }
}

@media(min-width: 1150px) {
    .contacts__item {
        &:nth-child(1) {
            margin-right: 100px;
        }
        margin-right: 90px;
    }
}

.contacts__location,
.contacts__phone {
    display: flex;
    flex-direction: column;

    .remark {
        font-size: @font-remark;
        line-height: 1.5;
    }
}

.contacts__location,
.contacts__phone,
.contacts__phone a {
    font-family: 'Circe-Regular';
    font-size: @font-standart;
    color: #FFFFFF;
    line-height: @line-height-standart;
    text-decoration: none;
}

.contacts-menu {
    display: flex;
    flex-direction: column;

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        margin-top: 15px;

        img {
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            top: 6px;
        }

        .remark {
            font-size: @font-remark;
            line-height: 1.5;
        }       
    }

    &__item,
    &__item a {
        font-family: 'Circe-Regular';
        font-size: @font-standart;
        color: #000000;
        line-height: @line-height-standart;
        text-decoration: none;
    }

    @media(min-width: 992px) {
        display: none;
    }
}