.card-advantage {

    &--width {
        width: 100%;
    }

    &--pad {
        padding: 30px 40px 30px 30px;
    }

    @media(min-width: 992px) {
        &--pad {
            padding: 30px 40px 30px 0;
        }
    }

    @media(min-width: 1200px) {
        &--width {
            width: 50%;
        }

        &--pad {
            padding: 30px 40px 60px 0;
        }
    }
}