.sertificates__wrapper {

    @media(min-width: 992px) {
        margin-top: 200px;
    }
}

.sertificate-card {
    position: relative;
    width: 50%;

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        filter: grayscale(1) brightness(44%);
    }

    &__title {
        position: relative;
        font-family: 'Circe-Light';
        font-weight: 300;
        font-size: 16px;
        line-height: 1.2;

        &--light {
            color: #FFFFFF;
        }
    }

    &:hover,
    &:focus,
    &:active {
        .sertificate-card__bg {
            animation: changeCardColor 0.4s linear forwards; 
        }
    }

    .aspect-ratio-box {
        overflow: hidden;
        padding-top: 369px / 528px * 100%;
        position: relative;
    }
    
    .aspect-ratio-box-inside {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 25px 25px;
    }



    @media(min-width: 476px) {

        &__title {
            font-size: 19px;
        }
    }

    @media(min-width: 576px) {

        &__title {
            font-size: 24px;
        }
    }

    @media(min-width: 768px) {

        &__title {
            font-size: 30px;
        }
    }

    @media(min-width: 992px) {
        width: 45%;

        &__title {
            font-size: 24px;
        }
    }

    @media(min-width: 1200px) {
        width: 33.3333%;

        &__title {
            font-size: 24px;
        }
    }

    @media(min-width: 1420px) {
        &__title {
            font-size: 32px;
        }

        .aspect-ratio-box-inside {
            padding: 40px 25px;
        }
    }

    @media(min-width: 1520px) {
        &__title {
            font-size: 36px;
        }
    }

    @media(min-width: 1650px) {
        &__title {
            font-size: 40px;
        }
    }
}