.text {
    position: relative;
    font-family: 'Circe-Regular';    

    &--standart {
        font-size: 16px;
        line-height: 1.5;
    }

    &--bold {
        font-family: 'Circe-Bold';
        font-weight: 700;
        font-size: 21px;
        line-height: 1.2; 
    }

    &--light {
        font-family: 'Circe-Light';
        font-weight: 300;
        line-height: 1.5; 
    }


    &--emphasize {
        font-size: 21px;
        line-height: 31px;
    }

    &--dark {
        color: #000000;
    }

    &--bigger {
        font-size: 17px;
    }

    &--big {
        font-size: 22px;
    }

    &--lh {
        line-height: 1.7;
    }

    &--bottom-line {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -1.25em;
            left: 0;
            width: 32px;
            height: 1.5px;
            background: @color-standart;
        }
    }

    &--huge {
        font-family: 'Circe-Bold';
        font-weight: 700;
        font-size: 60px;
        line-height: 1.5;
    }

    @media(min-width: 576px) {
        &--bigger {
            font-size: 19px;
        }
    }

    @media(min-width: 768px) {
        &--bigger {
            font-size: 21px;
        }

        &--emphasize {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @media(min-width: 1200px) {
        &--huge {
            font-size: 72px;
        }
    }

    @media(min-width: 1500px) {
        &--big {
            font-size: 22px;
        }        
    }

    @media(min-width: 1800px) {
        &--big {
            font-size: 25px;
        }        
    }
}

.text__box {
    &--width {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media(min-width: 768px) {
        &--width {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @media(min-width: 992px) {
        &--width {
            padding-left: 0;
        }
    }

    @media(min-width: 1300px) {
        &--width {
            width: 80%;
        }
    }

    @media(min-width: 1700px) {
        &--width {
            width: 60%;
        }
    }
}

.text--box-position {
    margin-left: auto;
    margin-right: auto;
}

.text--box-width {
    width: 96%;
}


.text--align-changed {
    text-align: center;
}

@media(min-width: 576px) {
    .text--box-width {
        width: 80%;
    }
}

@media(min-width: 992px) {
    .text--box-width {
        width: 90%;
    }

    .text--box-position {
        margin-left: 0;
        margin-right: auto;
    }

    .text--align-changed {
        text-align: left;
    }
}

@media(min-width: 1200px) {
    .text--box-width {
        width: 70%;
    }

    .text--box-position {
        margin-left: auto;
        margin-right: auto;
    }

    .text--align-changed {
        text-align: center;
    }
    
}

@media(min-width: 1400px) {
    .text--box-width {
        width: 60%;
    }
    
}

@media(min-width: 1600px) {
    .text--box-width {
        width: 50%;
    }
    
}
