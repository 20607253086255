.title {
    font-family: 'Circe-Bold';
    font-weight: 700;
    font-size: 44px;
    line-height: 1.49;
    color: #000000;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;

    &--pos-left {
        margin-left: 0;
    }

    &--d-none-lg {

        @media(min-width: 1400px) {
            display: none;
        }
    }

    &--d-block-lg {
        display: none;

        @media(min-width: 1400px) {
            display: block;
        }
    }

    &--bottom-line,
    &--bottom-line-left,
    &--bottom-line-left-low {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 50px;
            height: 2px;
            background: #000; 
        }
    }

    &--bottom-line {        

        &:after {
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &--bottom-line-left {

        &:after {
            bottom: -10px;
            left: 10px;
        }
    }

    &--bottom-line-left-low {

        &:after {
            bottom: -35px;
            left: 0;
        }
    }

    &--margin-side {
        margin-left: 15px;
        margin-right: 15px;
    }
    
    &--margin {
        margin-top: 80px;
        margin-bottom: 60px;
    }

    &--contacts-margin {
        margin-bottom: 100px;
    }

    @media(min-width: 768px) {
        font-size: 48px;

        &--margin-side {
            margin-left: 40px;
            margin-right: 40px;
        }
    }

    @media(min-width: 992px) {
        font-size: 52px;
        padding-left: 0;
        padding-right: 0;

        &--d-none {
            display: none;
        }

        &--margin {
            margin-top: 115px;
            margin-bottom: 80px;
        }

        &--margin-side {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media(min-width: 1200px) {
        &--margin {
            margin-top: 215px;
            margin-bottom: 80px;
        }
    }
}

.title--header,
.title--header-service {
    position: relative;
    font-family: 'Circe-Bold';
    font-size: 42px;
    line-height: 1;
    color: #FFFFFF;
    margin-bottom: 0.6em;
    z-index: 1;
    
    transform: translateY(120%);
    opacity: 0;
    transition: all 0.8s ease;


}

.title--header-service {
    line-height: 1;
}

.title--header._active,
.title--header-service._active {
    transform: translateY(0);
    opacity: 1;
}

.title__description {
    font-family: 'Circe-Light';
    font-size: 21px;
    line-height: 24px;
    color: #FFFFFF;

    transform: translateY(120%);
    opacity: 0;
    transition: all 0.8s ease;
}

.title__description._active {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.8s ease 0.2s;
}

.subtitle {
    font-family: 'Circe-Bold';
    font-size: 30px;
    line-height: 1.5;
    color: #000000;

    &--small {
        font-size: 25px;
        line-height: 1.2;
    }

    &--changed {
        font-size: 30px;
        line-height: 1.3;
    }
}

.subtitle--colored {
    font-family: 'Circe-Bold';
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding: 0.6em 1.87em;
    background: #000;
    width: fit-content;
}

@media(min-width: 576px) {
    .title--header {
        font-size: 55px;
        line-height: 55px;
    } 

    .title__description {
        font-size: 26px;
        line-height: 30px;
    }

    .title--header-service {
        font-size: 57px;
    }

    .subtitle {
        font-size: 32px;
    }
}

@media(min-width: 768px) {
    .subtitle--changed {
        font-size: 35px;
        line-height: 1.5;
    }
    .subtitle {
        font-size: 35px;
    }
    .subtitle--small {
        font-size: 25px;
    }

    .title--header-service {
        line-height: 1.2;
    }
}

@media(min-width: 992px) {
    .title--header {
        font-size: 60px;
        line-height: 48px;
    } 

    .title__description {
        font-size: 32px;
        line-height: 38px;
    }

    .title--header-service {
        font-size: 48px;
    }
}

@media(min-width: 1150px) {
    .title--header {
        font-size: 64px;
        line-height: 50px;
    } 

    .title__description {
        font-size: 30px;
        line-height: 34px;
    }
}

@media(min-width: 1260px) {
    .title--header {
        font-size: 72px;
        line-height: 60px;
    } 
    
    .title--header-service {
        font-size: 62px;
    }

    .title__description {
        font-size: 30px;
        line-height: 36px;
    }
}

@media(min-width: 1700px) {

    .title__description {
        font-size: 35px;
        line-height: 40px;
    }
}

@media(min-width: 1400px) {
    
    .title--header-service {
        font-size: 72px;
        
    } 
}