.img {

}

.img-stopper,
.img-stopper--changed,
.img-stopper--pos1 {
    min-width: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}