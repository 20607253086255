.rating-result {
}
.rating-result span {
	padding: 0;
	font-size: 19px;
	margin: 0 2px;
	line-height: 1;
	color: lightgrey;
}
.rating-result > span:before {
	content: '★';
}
.rating-result > span.active {
	color: #000;
}