@font-face {
  font-family: 'Circe-Regular';
  src: url('../fonts/Circe/Circe-Regular.eot');
  src: local('Circe'), local('Circe-Regular'), url('../fonts/Circe/Circe-Regular.eot') format('embedded-opentype'), url('../fonts/Circe/Circe-Regular.woff') format('woff'), url('../fonts/Circe/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Circe-ExtraLight';
  src: url('../fonts/Circe/Circe-ExtraLight.eot');
  src: local('Circe ExtraLight'), local('Circe-ExtraLight'), url('../fonts/Circe/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-ExtraLight.woff') format('woff'), url('../fonts/Circe/Circe-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Circe-Thin';
  src: url('../fonts/Circe/Circe-Thin.eot');
  src: local('Circe Thin'), local('Circe-Thin'), url('../fonts/Circe/Circe-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Thin.woff') format('woff'), url('../fonts/Circe/Circe-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Circe-Light';
  src: url('../fonts/Circe/Circe-Light.eot');
  src: local('Circe Light'), local('Circe-Light'), url('../fonts/Circe/Circe-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Light.woff') format('woff'), url('../fonts/Circe/Circe-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Circe-Bold';
  src: url('../fonts/Circe/Circe-Bold.eot');
  src: local('Circe Bold'), local('Circe-Bold'), url('../fonts/Circe/Circe-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Bold.woff') format('woff'), url('../fonts/Circe/Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Circe-ExtraBold';
  src: url('../fonts/Circe/Circe-ExtraBold.eot');
  src: local('Circe ExtraBold'), local('Circe-ExtraBold'), url('../fonts/Circe/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-ExtraBold.woff') format('woff'), url('../fonts/Circe/Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-90 {
  margin-top: 90px;
}
.mb-40 {
  margin-bottom: 40px;
}
.content-container__main {
  width: 100vw;
}
.content-container__main .title {
  text-align: center;
}
.content-container__main .title--pos-left {
  text-align: left;
}
.header {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 1px 35px 20px 15px;
  display: flex;
  align-items: flex-end;
}
@media (min-width: 576px) {
  .header {
    padding: 1px 85px 60px 50px;
  }
}
.header__slider,
.header__img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.header__slider .slick-list,
.header__img .slick-list,
.header__slider .slick-track,
.header__img .slick-track {
  height: 100%;
}
.header__img .img-stopper--changed {
  object-position: 0% 100%;
}
.header__img .img-stopper--pos1 {
  object-position: 80% 0%;
}
@media (min-width: 1200px) {
  .header__img .img-stopper {
    object-position: 60% 0;
  }
}
.header__title {
  position: relative;
  height: max-content;
  z-index: 1;
  margin-top: 90px;
}
.header__title--width {
  width: 100%;
}
@media (min-width: 450px) {
  .header__title--width {
    width: 80%;
  }
}
@media (min-width: 1050px) {
  .header__title--width {
    width: 50%;
  }
}
.header__title--width-wider {
  width: 100%;
}
@media (min-width: 450px) {
  .header__title--width-wider {
    width: 98%;
  }
}
@media (min-width: 1700px) {
  .header__title--width-wider {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .sertificates__wrapper {
    margin-top: 200px;
  }
}
.sertificate-card {
  position: relative;
  width: 50%;
}
.sertificate-card__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  filter: grayscale(1) brightness(44%);
}
.sertificate-card__title {
  position: relative;
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
}
.sertificate-card__title--light {
  color: #FFFFFF;
}
.sertificate-card:hover .sertificate-card__bg,
.sertificate-card:focus .sertificate-card__bg,
.sertificate-card:active .sertificate-card__bg {
  animation: changeCardColor 0.4s linear forwards;
}
.sertificate-card .aspect-ratio-box {
  overflow: hidden;
  padding-top: 69.88636364%;
  position: relative;
}
.sertificate-card .aspect-ratio-box-inside {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 25px;
}
@media (min-width: 476px) {
  .sertificate-card__title {
    font-size: 19px;
  }
}
@media (min-width: 576px) {
  .sertificate-card__title {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .sertificate-card__title {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .sertificate-card {
    width: 45%;
  }
  .sertificate-card__title {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .sertificate-card {
    width: 33.3333%;
  }
  .sertificate-card__title {
    font-size: 24px;
  }
}
@media (min-width: 1420px) {
  .sertificate-card__title {
    font-size: 32px;
  }
  .sertificate-card .aspect-ratio-box-inside {
    padding: 40px 25px;
  }
}
@media (min-width: 1520px) {
  .sertificate-card__title {
    font-size: 36px;
  }
}
@media (min-width: 1650px) {
  .sertificate-card__title {
    font-size: 40px;
  }
}
.comments__wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
}
@media (min-width: 992px) {
  .comments__wrapper {
    width: calc(100vw - 340px);
  }
}
.comment-card {
  width: fit-content;
  background: #F9F9F9;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 35px 20px 35px 30px;
  margin: 0 15px;
}
.comment-card__text {
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
.comment-card__text--dark {
  color: #000;
}
.comment-card__text--margin {
  margin-top: 20px;
}
.comment-card__name {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
}
.comment-card__name--margin {
  margin-top: 40px;
}
.comment-card__date {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}
.comment-card:not(:first-child) {
  margin-top: 20px;
}
@media (min-width: 576px) {
  .comment-card {
    max-width: 30%;
  }
  .comment-card:not(:first-child) {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .comment-card {
    padding: 45px 30px 45px 42px;
  }
}
.insta__wrapper {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.insta__wrapper--margin {
  margin-top: 70px;
}
.insta__wrapper--padding {
  padding-bottom: 85px;
}
.insta__wrapper--line {
  border-bottom: 1px solid #000000;
}
.insta__wrapper--pos-left {
  margin-left: 0;
}
@media (min-width: 768px) {
  .insta__wrapper {
    width: 80%;
  }
}
.insta-card {
  position: relative;
  width: 32%;
}
.insta-card:nth-child(n+4) {
  margin-top: 15px;
}
.insta-card .aspect-ratio-box {
  overflow: hidden;
  padding-top: 91.57894737%;
  position: relative;
}
.insta-card .aspect-ratio-box-inside {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1400px) {
  .insta-card:nth-child(n+4) {
    margin-top: 25px;
  }
}
.address {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.address__contacts {
  width: 100%;
}
.address__contacts--pad {
  padding: 60px 20px;
}
.address__map {
  width: 100%;
}
.address__map iframe {
  width: 100%;
}
.address .title--pos-left {
  text-align-last: left;
}
.address .contacts__elem,
.address .contacts__elem a {
  display: flex;
  flex-wrap: nowrap;
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  text-decoration: none;
  color: #000;
}
.address .contacts__elem:not(:last-child) {
  margin-bottom: 20px;
}
.address .contacts__elem img {
  width: 18px;
  height: 18px;
  object-fit: fill;
  margin-right: 15px;
}
@media (min-width: 576px) {
  .address .contacts__elem,
  .address .contacts__elem a {
    font-size: 20px;
  }
  .address .contacts__elem img {
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 992px) {
  .address {
    width: calc(100vw - 340px);
  }
}
@media (min-width: 1200px) {
  .address .contacts__elem,
  .address .contacts__elem a {
    font-size: 25px;
  }
  .address .contacts__elem img {
    width: 24px;
    height: 24px;
  }
}
@media (min-width: 1400px) {
  .address {
    flex-wrap: nowrap;
  }
  .address__contacts {
    width: 60%;
  }
  .address__map {
    width: 40%;
  }
  .address--margin {
    margin-top: 215px;
  }
  .address__contacts--pad {
    padding: 60px 80px;
  }
}
.nav-helper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 35px 30px 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  color: #000000;
}
.nav-helper__item {
  display: flex;
}
.nav-helper__item a {
  text-decoration: none;
  color: #000;
}
.nav-helper__item--documents {
  flex-direction: column;
  width: 100%;
}
.nav-helper__item--documents span {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.nav-helper__item--documents a {
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}
.nav-helper__item--ancors {
  flex-direction: column;
  width: 100%;
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: flex-start;
}
.nav-helper__item--ancors a {
  margin-top: 20px;
}
.nav-helper__item--insta {
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 25px;
}
.nav-helper__item--insta img {
  margin-right: 10px;
}
.nav-helper--margin {
  margin-top: 100px;
}
@media (min-width: 768px) {
  .nav-helper__item--ancors {
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
    align-items: center;
  }
  .nav-helper__item--ancors a {
    margin-top: 0;
  }
  .nav-helper__item--documents {
    width: auto;
  }
}
@media (min-width: 992px) {
  .nav-helper {
    width: calc(100% + 340px);
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    transform: translateX(-340px);
  }
  .nav-helper__item--ancors {
    width: 54%;
  }
  .nav-helper__item--insta {
    margin-top: 0;
    width: auto;
    border-top: none;
    padding-top: 0;
  }
  .nav-helper--margin {
    margin-top: 300px;
  }
}
@media (min-width: 1200px) {
  .nav-helper {
    padding: 35px 70px 40px;
  }
  .nav-helper__item--ncors {
    width: 50%;
  }
}
.section-wrapper--questions {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .section-wrapper--questions {
    width: 90%;
  }
}
@media (min-width: 1300px) {
  .section-wrapper--questions {
    width: 80%;
  }
}
.btn--question {
  position: relative;
  width: 100%;
  padding: 1.46em 60px 1.46em 1.67em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 19px;
  line-height: 1;
  text-align: left;
}
.btn--question:not(:first-child) {
  margin-top: 20px;
}
.btn--question._active {
  border: 1px solid #000000;
  border-bottom: none;
}
.btn--question._active::after {
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: center center;
}
.btn--question::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  top: 0;
  right: 25px;
  background: url("../img/icons/arrow-black.svg") no-repeat center;
  background-size: contain;
  transform: rotate(90deg);
  transform-origin: center center;
}
.btn--question:hover,
.btn--question:focus,
.btn--question:active {
  outline: 1px solid #000;
}
@media (min-width: 576px) {
  .btn--question {
    padding: 1.46em 80px 1.46em 1.67em;
  }
  .btn--question::after {
    right: 40px;
  }
}
@media (min-width: 768px) {
  .btn--question {
    font-size: 22px;
  }
  .btn--question::after {
    width: 12px;
  }
}
@media (min-width: 1300px) {
  .btn--question {
    font-size: 24px;
  }
}
.btn--question,
.block-answer {
  background: #F9F9F9;
}
.block-answer {
  border: 1px solid #000000;
  border-top: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s linear;
}
.block-answer p {
  padding: 1.46em 1.67em;
}
.section-makeup__wrapper {
  width: 100%;
}
.section-makeup__wrapper--colored {
  background: #F9F9F9;
}
.section-makeup__wrapper--pad {
  padding: 65px 0 50px 0;
}
.section-makeup__wrapper--margin {
  margin-top: 80px;
}
@media (min-width: 768px) {
  .section-makeup__wrapper--pad {
    padding: 85px 0 80px 45px;
  }
}
@media (min-width: 1200px) {
  .section-makeup__wrapper {
    width: 80%;
  }
  .section-makeup__wrapper--margin {
    margin-top: 120px;
  }
}
@media (min-width: 1300px) {
  .section-makeup__wrapper {
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .section-makeup__wrapper--margin {
    margin-top: 170px;
  }
}
@media (min-width: 1500px) {
  .section-makeup__wrapper {
    display: flex;
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .section-makeup__wrapper--pad {
    padding: 110px 0 95px 60px;
  }
}
.section-materials__wrapper {
  width: 100%;
}
.section-materials__wrapper--margin {
  margin-top: 110px;
}
.section-materials__descr {
  width: 100%;
}
.section-materials__img {
  width: 100%;
}
.section-materials__img--size {
  width: 40%;
}
.section-materials__img--position img {
  object-position: 10%;
}
.section-materials__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-materials--pad {
  padding: 15px 35px;
}
.section-materials--pad1 {
  padding: 90px 50px;
}
@media (min-width: 768px) {
  .section-materials__descr,
  .section-materials__img {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .section-materials__descr,
  .section-materials__img {
    width: 100%;
  }
  .section-materials--pad {
    padding: 15px 55px;
  }
}
@media (min-width: 1200px) {
  .section-materials__descr,
  .section-materials__img {
    width: 80%;
  }
}
@media (min-width: 1300px) {
  .section-materials__wrapper--margin {
    margin-top: 210px;
  }
  .section-materials__descr,
  .section-materials__img {
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .section-materials__wrapper {
    display: flex;
  }
  .section-materials__descr {
    width: 60%;
  }
  .section-materials__img {
    width: 40%;
  }
}
@media (min-width: 1500px) {
  .section-materials--pad {
    padding: 30px 90px;
  }
}
@media (min-width: 1600px) {
  .section-materials__img,
  .section-materials__descr {
    width: 50%;
  }
}
.section-makeup__description {
  padding-left: 30px;
}
.section-makeup__text {
  width: 100%;
  list-style-type: none;
  counter-reset: section;
  padding-right: 30px;
  padding-left: 30px;
}
.section-makeup__text li {
  position: relative;
}
.section-makeup__text li:before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  top: 0;
  left: -30px;
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
}
.section-makeup__text li:not(:first-child) {
  margin-top: 25px;
}
.section-makeup__text--margin {
  margin-top: 80px;
}
@media (min-width: 576px) {
  .section-makeup__text li:before {
    font-size: 36px;
  }
  .section-makeup__text li:not(:first-child) {
    margin-top: 15px;
  }
}
@media (min-width: 1600px) {
  .section-makeup__text {
    padding-right: 60px;
  }
}
@media (min-width: 1800px) {
  .section-makeup__text {
    padding-right: 140px;
  }
}
.section-makeup__advantages {
  padding-left: 70px;
  list-style-type: none;
}
.section-makeup__advantages--margin {
  margin-top: 60px;
}
.section-makeup__advantages--pad {
  padding-right: 30px;
}
.section-makeup__advantages li:not(:first-child) {
  margin-top: 40px;
}
.section-makeup__advantages li {
  position: relative;
}
.section-makeup__advantages li img {
  position: absolute;
  width: 40px;
  height: auto;
  left: -70px;
  top: 0;
}
.section-makeup__advantages--title {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}
.section-makeup__advantages--text {
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .section-makeup__advantages--pad {
    padding-right: 100px;
  }
}
@media (min-width: 768px) {
  .section-makeup__advantages {
    padding-left: 80px;
  }
  .section-makeup__advantages li img {
    width: 50px;
    left: -80px;
  }
  .section-makeup__advantages--pad {
    padding-right: 200px;
  }
}
@media (min-width: 992px) {
  .section-makeup__advantages--pad {
    padding-right: 50px;
  }
}
@media (min-width: 1200px) {
  .section-makeup__advantages--pad {
    padding-right: 90px;
  }
}
@media (min-width: 1700px) {
  .section-makeup__advantages--pad {
    padding-right: 130px;
  }
}
.section-makeup__video {
  width: 100%;
  margin-top: 35px;
}
.section-makeup__video iframe,
.section-makeup__video video {
  width: 100%;
}
@media (min-width: 576px) {
  .section-makeup__text {
    columns: 2;
    break-inside: avoid;
    column-gap: 10%;
  }
}
@media (min-width: 768px) {
  .section-makeup__description {
    padding-left: 0;
  }
  .section-makeup__video {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .section-makeup__video {
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .section-makeup__description {
    width: 55%;
  }
  .section-makeup__video {
    width: 45%;
    margin-top: 15px;
  }
  .section-makeup__video video {
    height: 100%;
  }
}
@media (min-width: 1600px) {
  .section-makeup__description {
    width: 50%;
  }
  .section-makeup__video {
    width: 50%;
  }
}
.section--top-margin {
  margin-top: 100px;
}
.section--top-margin1 {
  margin-top: 120px;
}
.section--bg {
  background: url('../img/service-cards/tatoo-remove-4.jpg') no-repeat;
  background-size: cover;
}
.section--size {
  width: 100%;
}
.section--pad {
  padding: 70px 40px 50px;
}
.section--side-pad {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .section--pad {
    padding: 160px 50px 50px;
  }
  .section--side-pad {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .section--pad {
    padding: 70px 50px 50px;
  }
  .section--side-pad {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .section--pad {
    padding: 160px 50px 50px;
  }
}
@media (min-width: 1300px) {
  .section--top-margin {
    margin-top: 200px;
  }
}
@media (min-width: 1500px) {
  .section--size {
    width: 80%;
  }
}
.section-inner__wrapper--pad {
  padding-bottom: 70px;
}
.section-inner__wrapper--line {
  border-bottom: 1px solid #000000;
}
.section-inner__wrapper--flex {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1800px) {
  .section-inner__wrapper--width {
    width: 80%;
  }
}
.section-duration {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.section-duration__img {
  position: relative;
  order: 3;
  margin-top: 40px;
}
.section-duration__img--size {
  width: 100%;
}
.section-duration__img img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .section-duration__img {
    order: 0;
    margin-top: 0;
  }
  .section-duration__img--size {
    width: 47%;
  }
}
@media (min-width: 992px) {
  .section-duration__img--size {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .section-duration__img--size {
    width: 41%;
  }
  .section-duration__img--position {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1400px) {
  .section-duration__img--size {
    width: 48%;
  }
}
.section-duration__text {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: fit-content;
}
.section-duration__text.section-duration__text--pos1:first-child,
.section-duration__text.section-duration__text--pos2:first-child,
.section-duration__text.section-duration__text--pos3:first-child,
.section-duration__text.section-duration__text--pos4:first-child,
.section-duration__text.section-duration__text--pos5:first-child,
.section-duration__text.section-duration__text--pos6:first-child,
.section-duration__text.section-duration__text--pos7:first-child,
.section-duration__text.section-duration__text--pos8:first-child,
.section-duration__text.section-duration__text--pos9:first-child {
  width: 100%;
  order: 0;
}
.section-duration__text.section-duration__text--pos1:last-child,
.section-duration__text.section-duration__text--pos2:last-child,
.section-duration__text.section-duration__text--pos3:last-child,
.section-duration__text.section-duration__text--pos4:last-child,
.section-duration__text.section-duration__text--pos5:last-child,
.section-duration__text.section-duration__text--pos6:last-child,
.section-duration__text.section-duration__text--pos7:last-child,
.section-duration__text.section-duration__text--pos8:last-child,
.section-duration__text.section-duration__text--pos9:last-child {
  order: 2;
  width: 100%;
  margin-top: 40px;
}
.section-duration__text.section-duration__text--pos1:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos2:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos3:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos4:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos5:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos6:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos7:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos8:last-child span:nth-child(2),
.section-duration__text.section-duration__text--pos9:last-child span:nth-child(2) {
  width: 30%;
}
.section-duration__text.section-duration__text--pos1:last-child span:last-child,
.section-duration__text.section-duration__text--pos2:last-child span:last-child,
.section-duration__text.section-duration__text--pos3:last-child span:last-child,
.section-duration__text.section-duration__text--pos4:last-child span:last-child,
.section-duration__text.section-duration__text--pos5:last-child span:last-child,
.section-duration__text.section-duration__text--pos6:last-child span:last-child,
.section-duration__text.section-duration__text--pos7:last-child span:last-child,
.section-duration__text.section-duration__text--pos8:last-child span:last-child,
.section-duration__text.section-duration__text--pos9:last-child span:last-child {
  width: 68%;
  margin-left: 2%;
}
.section-duration__text.section-duration__text--pos8 span:nth-child(2),
.section-duration__text.section-duration__text--pos9 span:nth-child(2) {
  width: 53%;
}
.section-duration__text.section-duration__text--pos8 span:last-child,
.section-duration__text.section-duration__text--pos9 span:last-child {
  width: 38%;
  margin-left: 2%;
}
.section-duration__text span:first-child {
  width: 100%;
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 65px;
  line-height: 1;
}
.section-duration__text span:nth-child(2),
.section-duration__text span:last-child {
  font-family: 'Circe-Regular';
  font-weight: 400;
}
.section-duration__text span:nth-child(2) {
  width: 28%;
  font-size: 35px;
  line-height: 1.2;
}
.section-duration__text span:last-child {
  width: 70%;
  font-size: 16px;
  line-height: 1.2;
  margin-left: 2%;
}
@media (min-width: 768px) {
  .section-duration__text.section-duration__text--pos1:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 44px);
  }
  .section-duration__text.section-duration__text--pos1:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-24px, -65px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos1:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos1:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos2:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 44px);
  }
  .section-duration__text.section-duration__text--pos2:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-24px, -65px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos2:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos2:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos3:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 44px);
  }
  .section-duration__text.section-duration__text--pos3:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-24px, -65px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos3:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos3:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos4:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 34px);
  }
  .section-duration__text.section-duration__text--pos4:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-24px, -65px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos4:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos4:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos5:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 34px);
  }
  .section-duration__text.section-duration__text--pos5:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-24px, -80px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos5:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos5:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos6:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 34px);
  }
  .section-duration__text.section-duration__text--pos6:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-8px, -101px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos6:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos6:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos7:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 34px);
  }
  .section-duration__text.section-duration__text--pos7:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-8px, -101px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos7:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos7:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos8:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 34px);
  }
  .section-duration__text.section-duration__text--pos8:first-child span:nth-child(2) {
    width: 54%;
  }
  .section-duration__text.section-duration__text--pos8:first-child span:last-child {
    width: 44%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos8:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-8px, -101px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos8:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos8:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos9:first-child {
    align-self: flex-end;
    width: 40%;
    transform: translate(-54px, 34px);
  }
  .section-duration__text.section-duration__text--pos9:first-child span:nth-child(2) {
    width: 54%;
  }
  .section-duration__text.section-duration__text--pos9:first-child span:last-child {
    width: 44%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos9:last-child {
    order: 0;
    align-self: flex-end;
    width: 45%;
    transform: translate(-8px, -101px);
    margin-top: 0;
  }
  .section-duration__text.section-duration__text--pos9:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos9:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text span:nth-child(2) {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .section-duration__text.section-duration__text--pos1:first-child {
    transform: translate(0, 0);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos1:last-child {
    width: 32%;
    transform: translate(0px, -35px);
  }
  .section-duration__text.section-duration__text--pos1:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos1:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos2:first-child {
    transform: translate(0, 85px);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos2:last-child {
    width: 32%;
    transform: translate(0px, -35px);
  }
  .section-duration__text.section-duration__text--pos2:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos2:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos3:first-child {
    transform: translate(0, 120px);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos3:last-child {
    width: 32%;
    transform: translate(0px, -35px);
  }
  .section-duration__text.section-duration__text--pos3:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos3:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos4:first-child {
    transform: translate(0, 0);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos4:last-child {
    width: 32%;
    transform: translate(0px, -35px);
  }
  .section-duration__text.section-duration__text--pos4:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos4:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos5:first-child {
    transform: translate(0, 0);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos5:last-child {
    width: 32%;
    transform: translate(0px, -35px);
  }
  .section-duration__text.section-duration__text--pos5:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos5:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos6:first-child {
    transform: translate(0, 0);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos6:last-child {
    width: 32%;
    transform: translate(0px, -60px);
  }
  .section-duration__text.section-duration__text--pos6:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos6:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos7:first-child {
    transform: translate(0, 0);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos7:last-child {
    width: 32%;
    transform: translate(0px, -60px);
  }
  .section-duration__text.section-duration__text--pos7:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos7:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos8:first-child {
    transform: translate(0, 80px);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos8:last-child {
    width: 32%;
    transform: translate(0px, -60px);
  }
  .section-duration__text.section-duration__text--pos8:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos8:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos9:first-child {
    transform: translate(0, 60px);
    align-self: initial;
    width: 30%;
  }
  .section-duration__text.section-duration__text--pos9:last-child {
    width: 32%;
    transform: translate(0px, -60px);
  }
  .section-duration__text.section-duration__text--pos9:last-child span:nth-child(2) {
    width: 35%;
  }
  .section-duration__text.section-duration__text--pos9:last-child span:last-child {
    width: 63%;
    margin-left: 2%;
  }
}
@media (min-width: 1400px) {
  .section-duration__text.section-duration__text--pos1:first-child {
    width: 20%;
    transform: translate(0, 47px);
  }
  .section-duration__text.section-duration__text--pos1:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 258px);
  }
  .section-duration__text.section-duration__text--pos1:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos1:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos2:first-child {
    width: 20%;
    transform: translate(0, 47px);
  }
  .section-duration__text.section-duration__text--pos2:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 414px);
  }
  .section-duration__text.section-duration__text--pos2:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos2:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos3:first-child {
    width: 20%;
    transform: translate(0, 47px);
  }
  .section-duration__text.section-duration__text--pos3:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 414px);
  }
  .section-duration__text.section-duration__text--pos3:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos3:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos4:first-child {
    width: 20%;
    transform: translate(0, -17px);
  }
  .section-duration__text.section-duration__text--pos4:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 258px);
  }
  .section-duration__text.section-duration__text--pos4:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos4:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos5:first-child {
    width: 20%;
    transform: translate(0, -17px);
  }
  .section-duration__text.section-duration__text--pos5:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 258px);
  }
  .section-duration__text.section-duration__text--pos5:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos5:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos6:first-child {
    width: 20%;
    transform: translate(0, -17px);
  }
  .section-duration__text.section-duration__text--pos6:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 220px);
  }
  .section-duration__text.section-duration__text--pos6:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos6:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos7:first-child {
    width: 20%;
    transform: translate(0, -17px);
  }
  .section-duration__text.section-duration__text--pos7:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 220px);
  }
  .section-duration__text.section-duration__text--pos7:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos7:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos8:first-child {
    width: 25%;
    transform: translate(0, -17px);
  }
  .section-duration__text.section-duration__text--pos8:first-child span:nth-child(2) {
    width: 52%;
  }
  .section-duration__text.section-duration__text--pos8:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 373px);
  }
  .section-duration__text.section-duration__text--pos8:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos8:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos9:first-child {
    width: 25%;
    transform: translate(0, -17px);
  }
  .section-duration__text.section-duration__text--pos9:first-child span:nth-child(2) {
    width: 52%;
  }
  .section-duration__text.section-duration__text--pos9:last-child {
    align-self: initial;
    width: 27%;
    transform: translate(0, 373px);
  }
  .section-duration__text.section-duration__text--pos9:last-child span:nth-child(2) {
    width: 33%;
  }
  .section-duration__text.section-duration__text--pos9:last-child span:last-child {
    width: 65%;
    margin-left: 2%;
  }
  .section-duration__text span:first-child {
    font-size: 78px;
  }
  .section-duration__text span:nth-child(2) {
    font-size: 32px;
  }
}
@media (min-width: 1700px) {
  .section-duration__text.section-duration__text--pos1:first-child {
    width: 20%;
    transform: translate(0, 47px);
  }
  .section-duration__text.section-duration__text--pos1:last-child {
    width: 25%;
    transform: translate(0, 300px);
  }
  .section-duration__text.section-duration__text--pos1:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos1:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos2:first-child {
    width: 20%;
    transform: translate(23px, 64px);
  }
  .section-duration__text.section-duration__text--pos2:last-child {
    width: 25%;
    transform: translate(-60px, 560px);
  }
  .section-duration__text.section-duration__text--pos2:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos2:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos3:first-child {
    width: 20%;
    transform: translate(23px, 64px);
  }
  .section-duration__text.section-duration__text--pos3:last-child {
    width: 25%;
    transform: translate(-60px, 560px);
  }
  .section-duration__text.section-duration__text--pos3:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos3:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos4:first-child {
    width: 20%;
    transform: translate(0, -39px);
  }
  .section-duration__text.section-duration__text--pos4:last-child {
    width: 25%;
    transform: translate(0, 300px);
  }
  .section-duration__text.section-duration__text--pos4:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos4:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos5:first-child {
    width: 20%;
    transform: translate(0, -15px);
  }
  .section-duration__text.section-duration__text--pos5:last-child {
    width: 25%;
    transform: translate(0, 262px);
  }
  .section-duration__text.section-duration__text--pos5:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos5:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos6:first-child {
    width: 20%;
    transform: translate(0, -15px);
  }
  .section-duration__text.section-duration__text--pos6:last-child {
    width: 25%;
    transform: translate(0, 220px);
  }
  .section-duration__text.section-duration__text--pos6:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos6:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos7:first-child {
    width: 20%;
    transform: translate(0, -15px);
  }
  .section-duration__text.section-duration__text--pos7:last-child {
    width: 25%;
    transform: translate(0, 220px);
  }
  .section-duration__text.section-duration__text--pos7:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos7:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos8:first-child {
    width: 23%;
    transform: translate(0, 45px);
  }
  .section-duration__text.section-duration__text--pos8:last-child {
    width: 25%;
    transform: translate(0, 455px);
  }
  .section-duration__text.section-duration__text--pos8:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos8:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text.section-duration__text--pos9:first-child {
    width: 23%;
    transform: translate(0, 18px);
  }
  .section-duration__text.section-duration__text--pos9:last-child {
    width: 25%;
    transform: translate(0, 455px);
  }
  .section-duration__text.section-duration__text--pos9:last-child span:nth-child(2) {
    width: 28%;
  }
  .section-duration__text.section-duration__text--pos9:last-child span:last-child {
    width: 70%;
    margin-left: 2%;
  }
  .section-duration__text span:first-child {
    font-size: 100px;
  }
}
.section-duration--margin {
  margin-top: 140px;
}
.section-duration--bottom-line {
  padding-bottom: 170px;
  border-bottom: 1px solid #000000;
}
@media (min-width: 500px) {
  .section-duration {
    width: 75%;
  }
}
@media (min-width: 576px) {
  .section-duration {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .section-duration {
    width: 100%;
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  .section-duration {
    max-width: 652px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .section-duration {
    max-width: 860px;
  }
}
@media (min-width: 1400px) {
  .section-duration {
    flex-direction: row;
    max-width: 1060px;
  }
}
@media (min-width: 1700px) {
  .section-duration {
    max-width: 1300px;
  }
}
.modal {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
}
.modal.open {
  opacity: 1;
  visibility: visible;
}
.modal__body {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__content {
  position: relative;
  max-width: 94%;
  max-height: 94vh;
  background: #F9F9F9;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
@media (min-width: 576px) {
  .modal__content {
    max-width: 510px;
    max-height: 90vh;
  }
}
@media (min-width: 768px) {
  .modal__content {
    max-width: 710px;
  }
}
@media (min-width: 992px) {
  .modal__content {
    max-width: 930px;
  }
}
@media (min-width: 1200px) {
  .modal__content {
    max-width: 1100px;
  }
}
@media (min-width: 1500px) {
  .modal__content {
    max-width: 1400px;
  }
}
.modal__photo {
  display: none;
  width: 30%;
  position: relative;
}
.modal__photo .aspect-ratio-box {
  overflow: hidden;
  padding-top: 84.08163265%;
  position: relative;
  height: 100%;
}
.modal__photo img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}
@media (min-width: 992px) {
  .modal__photo {
    display: block;
  }
}
.modal__description {
  width: 100%;
  overflow-y: auto;
}
.modal__description--pad {
  padding: 30px;
}
@media (min-width: 576px) {
  .modal__description--pad {
    padding: 30px 45px 30px 30px;
  }
}
@media (min-width: 992px) {
  .modal__description {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .modal__description--pad {
    padding: 35px 70px 35px 35px;
  }
}
@media (min-width: 1500px) {
  .modal__description--pad {
    padding: 55px 90px 55px 55px;
  }
}
.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  opacity: 0.6;
}
.modal__close:before,
.modal__close:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 92%;
  background: #000;
  left: 50%;
  top: 4%;
}
.modal__close:before {
  transform: rotate(45deg) translateX(-50%);
}
.modal__close:after {
  transform: rotate(-45deg) translateX(-50%);
}
.modal__close:hover,
.modal__close:focus,
.modal__close:active {
  opacity: 1;
}
@media (min-width: 576px) {
  .modal__close {
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
  }
}
.modal__title {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 28px;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .modal__title {
    font-size: 32px;
  }
}
@media (min-width: 1500px) {
  .modal__title {
    font-size: 35px;
  }
}
p.modal__text {
  font-size: 14px;
}
@media (min-width: 768px) {
  p.modal__text {
    font-size: inherit;
  }
}
.modal__button {
  display: flex;
  flex-direction: column;
}
.modal__button button:first-child {
  margin-bottom: 15px;
}
.modal__button button {
  font-size: 12px;
}
@media (min-width: 768px) {
  .modal__button {
    flex-direction: row;
  }
  .modal__button button {
    font-size: 13px;
  }
  .modal__button button:first-child {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
@media (min-width: 992px) {
  .modal__button button {
    font-size: 14px;
  }
}
.nav-aside {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  opacity: 0;
  height: 100vh;
  background: #ffffff;
  z-index: -1;
  transition: width 0.8s ease;
}
.nav-aside__wrapper {
  max-height: 100vh;
  overflow-y: scroll;
}
.nav-aside__wrapper--customized-scroll::-webkit-scrollbar {
  width: 9px;
  background-color: #f2f2f2;
}
.nav-aside__wrapper--customized-scroll::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}
.nav-aside__wrapper .nav__item:hover .nav-sub__list,
.nav-aside__wrapper .nav__item:focus .nav-sub__list {
  visibility: visible;
  opacity: 1;
}
.nav-aside--pad {
  opacity: 0;
  transition: opacity 0.4s ease-in 0.4s;
}
.nav-aside--open {
  width: 100%;
  opacity: 1;
  z-index: 3;
}
.nav-aside--open .nav-aside--pad {
  opacity: 1;
  padding: 12px 60px 60px 50px;
}
@media (min-width: 992px) {
  .nav-aside {
    opacity: 1;
    position: relative;
    min-width: 340px;
    width: 340px;
    height: 100vh;
    background: #ffffff;
    z-index: 3;
  }
  .nav-aside--pad {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .nav-aside .nav-aside__wrapper {
    position: fixed;
    z-index: 2;
    width: inherit;
    max-height: 100vh;
    overflow-y: visible;
  }
  .nav-aside--pad {
    padding: 12px 60px 60px 70px;
  }
}
.nav__list {
  position: relative;
  list-style-type: none;
  padding-left: 0;
}
.nav__list--bottom-line {
  position: relative;
}
.nav__list--bottom-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 32px;
  height: 1.5px;
  background: #000000;
}
.nav__list--pad {
  padding: 10px 0;
}
.nav__item {
  position: relative;
}
.nav__item:not(:last-child) {
  margin-bottom: 15px;
}
.nav__link {
  text-decoration: none;
  font-family: "Circe-Regular";
  font-size: 1em;
  line-height: 1.2;
  color: black;
}
.nav-toggle {
  z-index: 5;
}
.nav-toggle .ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nav-toggle .hamRotate.active {
  position: fixed;
  transform: rotate(45deg);
  right: 2%;
  top: 2%;
}
.nav-toggle .hamRotate.active .line {
  stroke: #000000;
}
.nav-toggle .line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #ffffff;
  stroke-width: 3.5;
  stroke-linecap: round;
}
.nav-toggle .ham4 .top {
  stroke-dasharray: 40 121;
}
.nav-toggle .ham4 .bottom {
  stroke-dasharray: 40 121;
}
.nav-toggle .ham4.active .top {
  stroke-dashoffset: -68px;
}
.nav-toggle .ham4.active .bottom {
  stroke-dashoffset: -68px;
}
.nav-mobile {
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 30px;
  z-index: 5;
}
.nav-mobile--d-none {
  display: flex;
}
.nav-mobile--bg-white {
  background: #ffffff;
}
@media (min-width: 992px) {
  .nav-mobile--d-none {
    display: none;
  }
}
body.touch .nav-sub__list,
body.mouse .nav-sub__list {
  display: none;
  margin-top: 15px;
  padding-left: 15px;
  transition: all 0.4s ease-out;
}
body.touch .nav-sub__list--open,
body.mouse .nav-sub__list--open {
  display: block;
}
@media (min-width: 992px) {
  body.mouse .nav-sub__list {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 200%;
    height: 100vh;
    right: -200%;
    top: 0;
    background: #fff;
    padding-left: 60px;
    padding-right: 20px;
    transition: all 0.4s ease-out;
  }
  body.mouse .nav-sub__list:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1000%;
    top: -1000%;
    left: 0;
    background: inherit;
  }
  body.mouse .nav-aside__wrapper .nav__item:hover .nav-sub__list,
  body.mouse .nav-aside__wrapper .nav__item:focus .nav-sub__list {
    visibility: visible;
    opacity: 1;
  }
}
.plus {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: transparent;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.plus:before,
.plus:after {
  content: "";
  position: absolute;
  background: #000;
  transition: all 0.3s linear;
}
.plus:before {
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  width: 1px;
  height: 100%;
}
.plus:after {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
}
.plus.active:before {
  transform: translateX(-50%) rotate(90deg);
}
.menu-inner__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-inner__wrapper--pad {
  padding-left: 30px;
  padding-right: 30px;
}
.menu-inner__block {
  width: 100%;
}
.menu-inner__block:nth-child(n+2) {
  margin-top: 40px;
}
.menu-inner--margin {
  margin-top: 80px;
}
@media (min-width: 576px) {
  .menu-inner__block {
    width: 42%;
  }
  .menu-inner__block:nth-child(n+2) {
    margin-top: 0;
  }
  .menu-inner__block:nth-child(n+3) {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .menu-inner__wrapper {
    justify-content: flex-start;
  }
  .menu-inner__block {
    width: 38%;
  }
  .menu-inner__block--margin-right:not(:last-child) {
    margin-right: 12%;
  }
  .menu-inner--margin {
    margin-top: 100px;
  }
}
@media (min-width: 992px) {
  .menu-inner__wrapper--pad {
    padding-left: 0;
    padding-right: 0;
  }
  .menu-inner--margin {
    margin-top: 140px;
  }
}
@media (min-width: 1300px) {
  .menu-inner__block {
    width: 30%;
  }
  .menu-inner__block--margin-right:not(:last-child) {
    margin-right: 10%;
  }
}
@media (min-width: 1400px) {
  .menu-inner__block {
    width: 25%;
  }
  .menu-inner__block--margin-right:not(:last-child) {
    margin-right: 10%;
  }
  .menu-inner__block:nth-child(n+3) {
    margin-top: 0;
  }
}
@media (min-width: 1700px) {
  .menu-inner__block {
    width: 20%;
  }
  .menu-inner__block--margin-right:not(:last-child) {
    margin-right: 170px;
  }
}
.contacts {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
}
.contacts--mob-fixed {
  z-index: 4;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}
.contacts--sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: #FFFFFF;
  animation: fixedMenu 1s forwards;
  z-index: 3;
}
.contacts--sticky .contacts__item,
.contacts--sticky .contacts__item a {
  color: #000000;
}
.contacts--sticky .icon--side-line::after {
  background: #000000;
}
.contacts--sticky .nav-mobile .nav-toggle .line {
  stroke: #000000;
}
.contacts--sticky .nav-mobile .nav-toggle .hamRotate.active {
  top: 15%;
}
.contacts--sticky .nav-mobile .logo-svg .logo-text {
  fill: #000000;
}
.contacts--sticky .nav-mobile {
  z-index: 2;
}
@media (min-width: 992px) {
  .contacts--sticky {
    z-index: 2;
  }
}
@keyframes fixedMenu {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.contacts__item {
  margin-right: 60px;
}
.contacts__item:nth-child(1) {
  margin-right: 80px;
}
.contacts__wrapper {
  display: flex;
  justify-content: right;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 14px 0 24px;
}
.contacts__wrapper--d-none {
  display: none;
}
@media (min-width: 992px) {
  .contacts__wrapper--d-none {
    display: flex;
  }
}
@media (min-width: 1150px) {
  .contacts__item {
    margin-right: 90px;
  }
  .contacts__item:nth-child(1) {
    margin-right: 100px;
  }
}
.contacts__location,
.contacts__phone {
  display: flex;
  flex-direction: column;
}
.contacts__location .remark,
.contacts__phone .remark {
  font-size: 0.75em;
  line-height: 1.5;
}
.contacts__location,
.contacts__phone,
.contacts__phone a {
  font-family: 'Circe-Regular';
  font-size: 1em;
  color: #FFFFFF;
  line-height: 1.75;
  text-decoration: none;
}
.contacts-menu {
  display: flex;
  flex-direction: column;
}
.contacts-menu__item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  margin-top: 15px;
}
.contacts-menu__item img {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  top: 6px;
}
.contacts-menu__item .remark {
  font-size: 0.75em;
  line-height: 1.5;
}
.contacts-menu__item,
.contacts-menu__item a {
  font-family: 'Circe-Regular';
  font-size: 1em;
  color: #000000;
  line-height: 1.75;
  text-decoration: none;
}
@media (min-width: 992px) {
  .contacts-menu {
    display: none;
  }
}
.block-about {
  display: flex;
  flex-wrap: wrap;
}
.block-about__photo {
  height: fit-content;
}
.block-about__description {
  background: #F9F9F9;
  padding: 25px 15px 64px 15px;
  height: fit-content;
  text-align: left;
}
.block-about__description > .text:not(:first-of-type) {
  margin-top: 35px;
}
.block-about__description > .text:nth-of-type(2) {
  margin-top: 55px;
}
.block-about__description--pad {
  padding: 45px 35px 60px 35px;
}
.block-about__description--pad1 {
  padding: 50px 30px 50px 30px;
}
.block-about__description--pad-smaller {
  padding: 45px 35px 60px 35px;
}
.block-about--margin {
  margin-top: 90px;
}
.block-about--margin1 {
  margin-top: 90px;
  margin-bottom: 100px;
}
.block-about--margin-small {
  margin-top: 90px;
}
@media (min-width: 576px) {
  .block-about__description {
    max-width: 80%;
    padding: 64px 45px 64px 50px;
  }
  .block-about__description--pad {
    padding: 65px 45px 60px 50px;
  }
  .block-about__description--pad1 {
    padding: 50px 30px 50px 30px;
  }
  .block-about__photo {
    max-width: 80%;
  }
}
@media (min-width: 768px) {
  .block-about__description {
    max-width: 85%;
    transform: translate(15%, -60px);
  }
  .block-about__description--pad {
    padding: 65px 125px 60px 50px;
  }
  .block-about__description--pad1 {
    padding: 70px 70px 80px 60px;
  }
  .block-about__photo {
    max-width: 70%;
  }
  .block-about--margin {
    margin-top: 210px;
    margin-bottom: 100px;
  }
  .block-about--margin1 {
    margin-top: 210px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .block-about__description--pad {
    padding: 65px 85px 60px 50px;
  }
  .block-about__description--pad1 {
    padding: 70px 70px 80px 60px;
  }
  .block-about--margin1 {
    margin-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .block-about__description {
    max-width: 70%;
    transform: translate(30%, -60px);
  }
  .block-about__photo {
    max-width: 60%;
  }
  .block-about--margin {
    margin-top: 210px;
    margin-bottom: 310px;
  }
}
@media (min-width: 1300px) {
  .block-about__description--pad {
    padding: 80px 150px 60px 50px;
  }
  .block-about__description--pad1 {
    padding: 70px 90px 90px 60px;
  }
}
@media (min-width: 1540px) {
  .block-about {
    flex-wrap: nowrap;
  }
  .block-about__description {
    max-width: 50%;
    transform: translate(-50px, 80px);
  }
  .block-about__photo {
    max-width: 50%;
  }
  .block-about__description {
    padding: 64px 85px 64px 50px;
  }
  .block-about__description--pad {
    padding: 80px 150px 60px 50px;
  }
  .block-about__description--pad1 {
    padding: 80px 90px 90px 60px;
  }
  .block-about__description--pad-smaller {
    padding: 80px 50px 60px 50px;
  }
}
@media (min-width: 1600px) {
  .block-about--margin1 {
    margin-bottom: 150px;
  }
}
@media (min-width: 1740px) {
  .block-about__description {
    max-width: 45%;
  }
}
.service__wrapper,
.sertificates__wrapper,
.masters__wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media (min-width: 768px) {
  .service__wrapper,
  .sertificates__wrapper,
  .masters__wrapper {
    justify-content: flex-end;
  }
}
.service-card {
  position: relative;
}
.service-card button {
  opacity: 0;
  transition: opacity 0.4s linear;
}
.service-card--width-third {
  width: 50%;
}
.service-card--width-third .aspect-ratio-box {
  padding-top: 90%;
}
.service-card--width-half {
  width: 50%;
}
.service-card--width-half .aspect-ratio-box {
  padding-top: 73.86363636%;
}
.service-card--width-half .service-card__bg {
  object-position: 50% 15%;
}
.service-card__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  filter: grayscale(1) brightness(44%);
}
.service-card__title {
  position: relative;
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
  transform: translateY(100%);
  transition: transform 0.4s linear;
}
.service-card__title--light {
  color: #FFFFFF;
}
.service-card:hover .service-card__bg,
.service-card:focus .service-card__bg,
.service-card:active .service-card__bg {
  animation: changeCardColor 0.4s linear forwards;
}
.service-card:hover button,
.service-card:focus button,
.service-card:active button {
  opacity: 1;
}
.service-card:hover .service-card__title,
.service-card:focus .service-card__title,
.service-card:active .service-card__title {
  transform: translateY(0);
}
.service-card .aspect-ratio-box {
  overflow: hidden;
  position: relative;
}
.service-card .aspect-ratio-box-inside {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 25px;
}
@media (min-width: 1420px) {
  .service-card .aspect-ratio-box-inside {
    padding: 40px 25px;
  }
}
@media (min-width: 476px) {
  .service-card__title {
    font-size: 19px;
  }
}
@media (min-width: 576px) {
  .service-card__title {
    font-size: 24px;
    transform: translateY(60%);
  }
}
@media (min-width: 768px) {
  .service-card__title {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .service-card--width-third {
    width: 45%;
  }
  .service-card__title {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .service-card--width-third {
    width: 33.3333%;
  }
  .service-card__title {
    font-size: 24px;
  }
}
@media (min-width: 1420px) {
  .service-card__title {
    font-size: 32px;
  }
}
@media (min-width: 1520px) {
  .service-card__title {
    font-size: 36px;
  }
}
@media (min-width: 1650px) {
  .service-card__title {
    font-size: 40px;
  }
}
@keyframes changeCardColor {
  0% {
    filter: grayscale(1) brightness(44%);
  }
  45% {
    filter: grayscale(0.65) brightness(65%);
  }
  75% {
    filter: grayscale(0.35) brightness(85%);
  }
  100% {
    filter: grayscale(0) brightness(100%);
  }
}
.masters__wrapper {
  justify-content: space-between;
}
@media (min-width: 992px) {
  .masters__wrapper {
    justify-content: flex-end;
    transform: translateX(1%);
  }
}
.masters-card {
  position: relative;
  width: 100%;
}
.masters-card__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.masters-card__name {
  position: relative;
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 24px;
  line-height: 1.48;
}
.masters-card__name--light {
  color: #000;
}
.masters-card__position {
  position: relative;
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.masters-card__position--light {
  color: #000;
}
.masters-card:nth-child(n+3) {
  margin-top: 15px;
}
.masters-card .aspect-ratio-box-inside {
  padding: 25px 10px;
}
@media (min-width: 460px) {
  .masters-card {
    width: 70%;
  }
}
@media (min-width: 576px) {
  .masters-card {
    width: 49%;
  }
  .masters-card__name--light,
  .masters-card__position--light {
    color: #FFFFFF;
  }
  .masters-card__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }
  .masters-card .aspect-ratio-box {
    overflow: hidden;
    padding-top: 139.14209115%;
    position: relative;
  }
  .masters-card .aspect-ratio-box-inside {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px 25px;
  }
  .masters-card .btn--d-none {
    display: none;
  }
}
@media (min-width: 576px) and (min-width: 1420px) {
  .masters-card .aspect-ratio-box-inside {
    padding: 45px 30px;
  }
}
@media (min-width: 768px) {
  .masters-card {
    width: 32.3%;
  }
  .masters-card:nth-child(n+3) {
    margin-top: 0;
  }
  .masters-card:nth-child(n+4) {
    margin-top: 15px;
  }
}
@media (min-width: 992px) {
  .masters-card {
    width: 45%;
    margin-right: 1%;
  }
  .masters-card:nth-child(n+4) {
    margin-top: 0;
  }
  .masters-card:nth-child(n+3) {
    margin-top: 10px;
  }
  .masters-card__name {
    font-size: 25px;
  }
}
@media (min-width: 1200px) {
  .masters-card {
    width: 32%;
  }
  .masters-card:nth-child(n+3) {
    margin-top: 0;
  }
  .masters-card:nth-child(n+4) {
    margin-top: 25px;
  }
}
@media (min-width: 1300px) {
  .masters-card {
    width: 24%;
  }
  .masters-card__name {
    font-size: 22px;
  }
  .masters-card:nth-child(n+4) {
    margin-top: 0;
  }
  .masters-card:nth-child(n+5) {
    margin-top: 25px;
  }
}
@media (min-width: 1400px) {
  .masters-card__name {
    font-size: 25px;
  }
}
.proposals__wrapper {
  width: 100%;
  font-size: 0;
}
.proposals__wrapper .aspect-ratio-box {
  overflow: hidden;
  padding-top: 123.2%;
  position: relative;
}
.proposals__wrapper .aspect-ratio-box-inside {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000;
}
@media (min-width: 576px) {
  .proposals__wrapper .aspect-ratio-box {
    padding-top: 49.02789518%;
  }
}
@media (min-width: 768px) {
  .proposals__wrapper .aspect-ratio-box {
    padding-top: 36.63929248%;
  }
}
.form-consultation,
.block-about__description {
  text-align: center;
}
.form-consultation--size,
.block-about__description--size {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.form-consultation--align,
.block-about__description--align {
  text-align: left;
}
.form-consultation--margin,
.block-about__description--margin {
  margin-top: 110px;
}
.form-consultation form,
.block-about__description form {
  margin-top: 45px;
}
.form-consultation form input,
.block-about__description form input,
.form-consultation form select,
.block-about__description form select {
  width: 100%;
  background: #F9F9F9;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
  transition: border 0.3s linear;
}
.form-consultation form input:hover,
.block-about__description form input:hover,
.form-consultation form select:hover,
.block-about__description form select:hover,
.form-consultation form input:focus,
.block-about__description form input:focus,
.form-consultation form select:focus,
.block-about__description form select:focus,
.form-consultation form input:active,
.block-about__description form input:active,
.form-consultation form select:active,
.block-about__description form select:active {
  outline: none;
  border: 0.5px solid rgba(0, 0, 0, 0.8);
}
.form-consultation form select,
.block-about__description form select {
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  appearance: none;
  text-align-last: center;
}
.form-consultation form select:after,
.block-about__description form select:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: url(../img/icons/arrow-black.svg);
  background-position: 100% 50%;
  z-index: 2;
}
.form-consultation form select:active,
.block-about__description form select:active,
.form-consultation form select:focus,
.block-about__description form select:focus {
  color: #000000;
}
.form-consultation--form-changed form input,
.block-about__description--form-changed form input,
.form-consultation--form-changed form select,
.block-about__description--form-changed form select {
  background: #fff;
  text-align: left;
  text-align-last: left;
}
@media (min-width: 576px) {
  .form-consultation--size,
  .block-about__description--size {
    width: 500px;
  }
}
@media (min-width: 768px) {
  .form-consultation--margin,
  .block-about__description--margin {
    margin-top: 150px;
  }
}
@media (min-width: 1400px) {
  .form-consultation--margin,
  .block-about__description--margin {
    margin-top: 290px;
  }
}
.procedure-description {
  display: flex;
  flex-wrap: wrap;
}
.procedure-description p:first-child {
  margin-right: 30px;
}
.procedure-description__price,
.procedure-description__duration {
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
}
.procedure-description__price span,
.procedure-description__duration span {
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 20px;
  padding-left: 15px;
}
.procedure-description--light {
  color: #ffffff;
}
@media (min-width: 1200px) {
  .procedure-description__price,
  .procedure-description__duration {
    font-size: 16px;
  }
  .procedure-description__price span,
  .procedure-description__duration span {
    font-size: 24px;
  }
}
.section-stages {
  display: flex;
  flex-direction: column;
  align-items: center;
  counter-reset: section;
  padding-left: 20px;
}
.section-stages__item {
  width: 90%;
  position: relative;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.section-stages__item:after {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  top: 0;
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
}
.section-stages__item:nth-child(odd) {
  padding-left: 70px;
  text-align: left;
  border-left: 2px solid #000;
}
.section-stages__item:nth-child(odd):before {
  content: "";
  position: absolute;
  left: -15px;
  top: 0;
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border: 1.55556px solid #000000;
  border-radius: 50%;
}
.section-stages__item:nth-child(odd):after {
  left: 40px;
}
.section-stages__item:nth-child(even) {
  padding-left: 70px;
  text-align: left;
  border-left: 2px solid #000;
}
.section-stages__item:nth-child(even):before {
  content: "";
  position: absolute;
  left: -15px;
  top: 0;
  width: 24px;
  height: 24px;
  background: #000;
  border: 1.55556px solid #000000;
  border-radius: 50%;
}
.section-stages__item:nth-child(even):after {
  left: 40px;
}
.section-stages__item:last-child {
  border-color: #fff;
}
@media (min-width: 576px) {
  .section-stages {
    padding-left: 0;
  }
  .section-stages__item:after {
    font-size: 42px;
  }
  .section-stages__item:nth-child(odd),
  .section-stages__item:nth-child(even) {
    padding-left: 90px;
  }
  .section-stages__item:nth-child(odd):after,
  .section-stages__item:nth-child(even):after {
    left: 60px;
  }
}
@media (min-width: 768px) {
  .section-stages__item {
    width: 48%;
  }
  .section-stages__item:nth-child(odd) {
    transform: translateX(calc(50% - 2px));
    text-align: left;
    border-left: 2px solid #000;
  }
  .section-stages__item:nth-child(odd):before {
    left: -15px;
  }
  .section-stages__item:nth-child(odd):after {
    left: 60px;
  }
  .section-stages__item:nth-child(even) {
    padding-left: 0;
    padding-right: 90px;
    transform: translateX(-50%);
    text-align: right;
    border-right: 2px solid #000;
    border-left: none;
  }
  .section-stages__item:nth-child(even):before {
    right: -15px;
    left: auto;
  }
  .section-stages__item:nth-child(even):after {
    right: 60px;
    left: auto;
  }
  .section-stages__item:last-child {
    border-color: #fff;
  }
}
@media (min-width: 992px) {
  .section-stages__item {
    width: 90%;
  }
  .section-stages__item:nth-child(odd) {
    transform: translateX(0);
  }
  .section-stages__item:nth-child(even) {
    padding-left: 90px;
    transform: translateX(0);
    text-align: left;
    border-left: 2px solid #000;
    border-right: none;
  }
  .section-stages__item:nth-child(even):before {
    left: -15px;
  }
  .section-stages__item:nth-child(even):after {
    left: 60px;
  }
  .section-stages__item:last-child {
    border-color: #fff;
  }
}
@media (min-width: 1200px) {
  .section-stages__item {
    width: 48%;
  }
  .section-stages__item:nth-child(odd) {
    transform: translateX(calc(50% - 2px));
    text-align: left;
    border-left: 2px solid #000;
  }
  .section-stages__item:nth-child(odd):before {
    left: -15px;
  }
  .section-stages__item:nth-child(odd):after {
    left: 60px;
  }
  .section-stages__item:nth-child(even) {
    padding-left: 0;
    padding-right: 90px;
    transform: translateX(-50%);
    text-align: right;
    border-right: 2px solid #000;
    border-left: none;
  }
  .section-stages__item:nth-child(even):before {
    right: -15px;
    left: auto;
  }
  .section-stages__item:nth-child(even):after {
    right: 60px;
    left: auto;
  }
  .section-stages__item:last-child {
    border-color: #fff;
  }
}
@media (min-width: 1500px) {
  .section-stages__item {
    width: 30%;
  }
}
.specialist__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.specialist__wrapper--margin {
  margin-top: 70px;
}
@media (min-width: 576px) {
  .specialist__wrapper {
    padding-left: 30px;
  }
}
@media (min-width: 992px) {
  .specialist__wrapper {
    padding-left: 0px;
  }
}
@media (min-width: 1450px) {
  .specialist__wrapper {
    flex-wrap: nowrap;
  }
}
.specialist-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.specialist-card:nth-child(n+2) {
  margin-top: 50px;
}
.specialist-card__photo {
  width: 100%;
}
.specialist-card .aspect-ratio-box {
  overflow: hidden;
  padding-top: 62.93333333%;
  position: relative;
}
.specialist-card .aspect-ratio-box-inside {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: left 10%;
}
.specialist-card__description {
  width: 100%;
  background: #F9F9F9;
  padding: 30px 25px 40px 35px;
}
.specialist-card__name {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5;
}
.specialist-card__name--dark {
  color: #000000;
}
.specialist-card__position {
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
.specialist-card__position--dark {
  color: #000000;
}
.specialist-card__price p {
  display: inline;
}
.specialist-card__price p:first-child {
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: #737373;
}
.specialist-card__price p:last-child {
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 19px;
  line-height: 1.2;
}
.specialist-card__price--margin {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .specialist-card {
    flex-wrap: nowrap;
  }
  .specialist-card .aspect-ratio-box {
    padding-top: 124.09638554%;
  }
  .specialist-card .aspect-ratio-box-inside {
    object-position: initial;
  }
  .specialist-card__photo {
    width: 38%;
  }
  .specialist-card__description {
    width: 62%;
    transform: translate(-30px, 30px);
  }
}
@media (min-width: 768px) {
  .specialist-card {
    width: 80%;
  }
}
@media (min-width: 1450px) {
  .specialist-card {
    width: 70%;
  }
}
@media (min-width: 1450px) {
  .specialist-card {
    width: 50%;
  }
  .specialist-card:nth-child(n+2) {
    margin-top: 0;
  }
  .specialist-card:nth-child(n+3) {
    margin-top: 50px;
  }
  .specialist-card__description {
    width: 60%;
    padding: 45px 35px 50px 45px;
  }
  .specialist-card__name {
    font-size: 32px;
  }
  .specialist-card__price p:last-child {
    font-size: 21px;
  }
}
@media (min-width: 1600px) {
  .specialist-card__description {
    padding: 55px 35px 70px 65px;
  }
  .specialist-card__name {
    font-size: 35px;
  }
}
@media (min-width: 1800px) {
  .specialist-card__photo {
    width: 41%;
  }
  .specialist-card__description {
    width: 55%;
  }
}
.card-service {
  display: flex;
  flex-direction: column;
}
.card-service:not(:first-child) {
  margin-top: 60px;
}
.card-service--margin {
  margin-top: 80px;
}
.card-service__photo {
  width: 100%;
  z-index: 1;
}
.card-service__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.card-service__description {
  width: 100%;
  z-index: 0;
}
.card-service__description--bg {
  background: #F9F9F9;
}
.card-service__description--pad {
  padding: 45px 30px 35px 35px;
}
@media (min-width: 576px) {
  .card-service {
    align-items: flex-end;
  }
  .card-service__photo {
    width: 50%;
  }
  .card-service__description {
    transform: translateY(-8%);
  }
}
@media (min-width: 768px) {
  .card-service--margin {
    margin-top: 0;
  }
  .card-service__photo {
    width: 40%;
  }
  .card-service__description {
    transform: translateY(-15%);
  }
  .card-service__description--pad {
    padding: 55px 200px 55px 55px;
  }
}
@media (min-width: 992px) {
  .card-service__description--pad {
    padding: 55px 120px 55px 55px;
  }
}
@media (min-width: 1200px) {
  .card-service__description--pad {
    padding: 55px 220px 55px 55px;
  }
}
@media (min-width: 1400px) {
  .card-service {
    flex-direction: row;
    align-items: initial;
  }
  .card-service__description {
    width: 65%;
    transform: translateY(0);
  }
  .card-service__description--pad {
    padding: 55px 60px 55px 55px;
  }
}
@media (min-width: 1600px) {
  .card-service__description--pad {
    padding: 55px 210px 55px 55px;
  }
}
@media (min-width: 1800px) {
  .card-service__description--pad {
    padding: 55px 305px 55px 55px;
  }
}
.block--flex {
  display: flex;
  flex-direction: column;
}
.block--size {
  width: 100%;
}
.block--margin {
  margin-top: 140px;
}
.block--margin-smaller {
  margin-top: 130px;
}
.block--pad-bottom {
  padding-bottom: 40px;
}
.block--bottom-line {
  border-bottom: 1px solid #000000;
}
.block--width {
  width: 96%;
}
.block--pad-left {
  padding-left: 30px;
}
@media (min-width: 576px) {
  .block--width {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .block--pad-left {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .block--width {
    width: 80%;
  }
}
@media (min-width: 1500px) {
  .block--size {
    width: 100%;
  }
  .block--flex {
    flex-direction: row;
  }
  .block--margin {
    margin-top: 240px;
  }
  .block--pad-bottom {
    padding-bottom: 100px;
  }
  .block--width {
    width: 60%;
  }
}
.block__img--size,
.block__img--size-narrow,
.block__img--size-bigger,
.block__img--size-medium {
  height: fit-content;
}
.block__img--size img,
.block__img--size-narrow img,
.block__img--size-bigger img,
.block__img--size-medium img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block__img--size,
.block__img--size-medium,
.block__img--size-bigger,
.block__img--size-narrow {
  width: 100%;
}
.block__img--flex-order {
  order: 0;
}
@media (min-width: 480px) {
  .block__img--size-narrow,
  .block__img--size-medium {
    width: 80%;
  }
}
@media (min-width: 576px) {
  .block__img--size {
    width: 85%;
  }
  .block__img--size-narrow,
  .block__img--size-medium {
    width: 70%;
  }
  .block__img--size-bigger {
    width: 80%;
  }
}
@media (min-width: 768px) {
  .block__img--size {
    width: 65%;
  }
  .block__img--size-narrow,
  .block__img--size-medium {
    width: 60%;
  }
}
@media (min-width: 992px) {
  .block__img--size {
    width: 70%;
  }
  .block__img--size-narrow {
    width: 55%;
  }
  .block__img--size-medium {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .block__img--size {
    width: 50%;
  }
  .block__img--size-medium {
    width: 50%;
  }
  .block__img--size-narrow {
    width: 45%;
  }
  .block__img--size-bigger {
    width: 60%;
  }
}
@media (min-width: 1500px) {
  .block__img--size {
    width: 45%;
  }
  .block__img--size-medium {
    width: 37%;
  }
  .block__img--size-narrow {
    width: 32%;
  }
  .block__img--flex-order {
    order: 1;
  }
  .block__img--size-bigger {
    width: 47%;
  }
}
@media (min-width: 1700px) {
  .block__img--size-narrow {
    width: 26%;
  }
}
.block__text--size,
.block__text--size1,
.block__text--size-changed,
.block__text--size-smaller {
  width: 100%;
}
.block__text--pad,
.block__text--pad-left {
  padding: 20px 30px 40px 30px;
}
.block__text--pad1 {
  padding: 60px 15px 40px 15px;
}
.block__text--pad2 {
  padding: 60px 15px;
}
.block__text--flex-order {
  order: 1;
}
@media (min-width: 576px) {
  .block__text--pad1 {
    padding: 60px 40px 40px 30px;
  }
  .block__text--pad2 {
    padding: 70px 30px 60px 30px;
  }
}
@media (min-width: 768px) {
  .block__text--size-changed {
    width: 65%;
  }
  .block__text--pad2 {
    padding: 70px 150px 60px 30px;
  }
}
@media (min-width: 992px) {
  .block__text--pad,
  .block__text--pad-left {
    padding: 20px 40px 40px 0;
  }
  .block__text--pad1 {
    padding: 60px 40px 40px 0;
  }
  .block__text--size-changed {
    width: 100%;
  }
  .block__text--size1 {
    width: 80%;
  }
  .block__text--pad2 {
    padding: 60px 30px 30px 0;
  }
}
@media (min-width: 1200px) {
  .block__text--size-changed {
    width: 60%;
  }
  .block__text--size1 {
    width: 50%;
  }
}
@media (min-width: 1500px) {
  .block__text--pad {
    padding: 40px 40px 40px 90px;
  }
  .block__text--pad1 {
    padding: 60px 40px 40px 70px;
  }
  .block__text--pad2 {
    padding: 20px 60px 30px 50px;
  }
  .block__text--size {
    width: 55%;
  }
  .block__text--size1 {
    width: 63%;
  }
  .block__text--size-changed {
    width: 55%;
  }
  .block__text--flex-order {
    order: 0;
  }
  .block__text--size-smaller {
    width: 43%;
  }
}
@media (min-width: 1600px) {
  .block__text--size-changed {
    width: 45%;
  }
  .block__text--pad2 {
    padding: 30px 80px 30px 50px;
  }
}
@media (min-width: 1700px) {
  .block__text--size-smaller {
    width: 32%;
  }
  .block__text--size1 {
    width: 45%;
  }
  .block__text--pad2 {
    padding: 60px 15px 60px 50px;
  }
}
@media (min-width: 1800px) {
  .block__text--pad1 {
    padding: 110px 40px 40px 90px;
  }
  .block__text--pad2 {
    padding: 90px 35px 60px 50px;
  }
}
.block-inner {
  position: relative;
  background: inherit;
  overflow: hidden;
  text-align: center;
}
.block-inner h3,
.block-inner p {
  position: relative;
}
.block-inner--pad {
  padding: 35px 30px;
}
.block-inner__bg {
  position: absolute;
  width: 110%;
  height: 110%;
  top: -5%;
  left: -5%;
  filter: blur(10px) saturate(100%) contrast(20%) brightness(160%);
  background: inherit;
}
.block-inner--size {
  width: 100%;
}
.block-inner--position {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .block-inner--pad {
    padding: 45px 100px;
  }
}
@media (min-width: 768px) {
  .block-inner--size {
    width: 550px;
  }
  .block-inner--pad {
    padding: 45px 100px;
  }
}
@media (min-width: 992px) {
  .block-inner--size {
    width: 480px;
  }
  .block-inner--pad {
    padding: 45px 80px;
  }
}
@media (min-width: 1200px) {
  .block-inner--size {
    width: 550px;
  }
  .block-inner--pad {
    padding: 45px 100px;
  }
}
.card--width {
  width: 100%;
}
.card--margin:nth-child(n+2) {
  margin-top: 50px;
}
@media (min-width: 440px) {
  .card--width {
    width: 70%;
  }
}
@media (min-width: 576px) {
  .card--width {
    width: 43%;
  }
  .card--margin:nth-child(n+2) {
    margin-top: 0;
  }
  .card--margin:nth-child(n+3) {
    margin-top: 50px;
  }
}
@media (min-width: 768px) {
  .card--width {
    width: 30%;
  }
  .card--margin:nth-child(n+3) {
    margin-top: 0;
  }
  .card--margin:nth-child(n+4) {
    margin-top: 50px;
  }
}
@media (min-width: 992px) {
  .card--width {
    width: 46%;
  }
  .card--margin:nth-child(n+4) {
    margin-top: 0;
  }
  .card--margin:nth-child(n+3) {
    margin-top: 50px;
  }
}
@media (min-width: 1200px) {
  .card--width {
    width: 30%;
  }
  .card--margin:nth-child(n+3) {
    margin-top: 0;
  }
  .card--margin:nth-child(n+4) {
    margin-top: 50px;
  }
}
@media (min-width: 1300px) {
  .card--width {
    width: 26%;
  }
}
@media (min-width: 1500px) {
  .card--width {
    width: 19%;
  }
  .card--margin:nth-child(n+4) {
    margin-top: 0;
  }
  .card--margin:not(:last-child) {
    margin-right: 80px;
  }
}
@media (min-width: 1700px) {
  .card--width {
    width: 17%;
  }
}
.card__title {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
}
.card__title--margin {
  margin-top: 20px;
}
.card__text--margin {
  margin-top: 10px;
}
.card__img {
  height: 60px;
}
.card__img img {
  height: 100%;
}
@media (min-width: 768px) {
  .card__img {
    height: 70px;
  }
}
.card-work--width {
  width: 100%;
}
.card-work--flex {
  display: flex;
  flex-wrap: wrap;
}
.card-work--pos-chess:nth-child(even) .card-work__img {
  order: 1;
}
.card-work--pos-chess:nth-child(even) .card-work__text {
  order: 2;
}
.card-work--pos-chess:nth-child(even) .card-work__text--pad {
  padding: 50px 30px 50px 30px;
}
.card-work__img--size,
.card-work__text--size {
  width: 100%;
}
.card-work__img {
  height: auto;
}
.card-work__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-work__text--pad {
  padding: 50px 30px 50px 30px;
}
.card-work:last-child {
  border-bottom: 1px solid #000000;
}
@media (min-width: 576px) {
  .card-work__img--size,
  .card-work__text--size {
    width: 85%;
  }
  .card-work__text--pad {
    padding: 50px 45px 50px 40px;
  }
  .card-work--pos-chess:nth-child(even) .card-work__text--pad {
    padding: 50px 45px 50px 40px;
  }
}
@media (min-width: 768px) {
  .card-work__img--size,
  .card-work__text--size {
    width: 70%;
  }
  .card-work:last-child {
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .card-work__img--size,
  .card-work__text--size {
    width: 100%;
  }
  .card-work__text--pad {
    padding: 50px 45px 50px 0;
  }
  .card-work--pos-chess:nth-child(even) .card-work__text--pad {
    padding: 50px 45px 50px 0;
  }
}
@media (min-width: 1100px) {
  .card-work__img--size,
  .card-work__text--size {
    width: 80%;
  }
}
@media (min-width: 1300px) {
  .card-work__img--size,
  .card-work__text--size {
    width: 50%;
  }
  .card-work--pos-chess:nth-child(even) .card-work__img {
    order: 2;
  }
  .card-work--pos-chess:nth-child(even) .card-work__text {
    order: 1;
  }
  .card-work--pos-chess:nth-child(even) .card-work__text--pad {
    padding: 50px 45px 50px 0;
  }
  .card-work__text--pad {
    padding: 50px 45px 50px 50px;
  }
}
@media (min-width: 1500px) {
  .card-work__text--pad {
    padding: 80px 70px 60px 60px;
  }
  .card-work--pos-chess:nth-child(even) .card-work__text--pad {
    padding: 90px 60px 60px 0;
  }
}
@media (min-width: 1800px) {
  .card-work--width {
    width: 80%;
  }
}
.card-advantage--width {
  width: 100%;
}
.card-advantage--pad {
  padding: 30px 40px 30px 30px;
}
@media (min-width: 992px) {
  .card-advantage--pad {
    padding: 30px 40px 30px 0;
  }
}
@media (min-width: 1200px) {
  .card-advantage--width {
    width: 50%;
  }
  .card-advantage--pad {
    padding: 30px 40px 60px 0;
  }
}
.icon-svg {
  fill: #ffffff;
}
.logo-svg .logo-text {
  fill: #ffffff;
}
.logo-svg .logo-text--dark {
  fill: #000000;
}
.svg-eyelash__left,
.svg-eye-arrow__left,
.svg-eyelash__right,
.svg-eye-arrow__right,
.svg-arrows-shadow__left,
.svg-arrows-shadow__right,
.svg-microblending__left,
.svg-microblending__right,
.svg-brows-mixed__left,
.svg-brows-mixed__right,
.svg-brows-hair__left,
.svg-brows-hair__right,
.svg-brows-correction__left,
.svg-brows-correction__right,
.svg-lipstick__left,
.svg-lipstick__right,
.svg-lipstick-watercolor__left,
.svg-lipstick-watercolor__right {
  display: none;
}
@media (min-width: 768px) {
  .svg-eyelash__left,
  .svg-eye-arrow__left,
  .svg-eyelash__right,
  .svg-eye-arrow__right,
  .svg-arrows-shadow__left,
  .svg-arrows-shadow__right,
  .svg-microblending__left,
  .svg-microblending__right,
  .svg-brows-mixed__left,
  .svg-brows-mixed__right,
  .svg-brows-hair__left,
  .svg-brows-hair__right,
  .svg-brows-correction__left,
  .svg-brows-correction__right,
  .svg-lipstick__left,
  .svg-lipstick__right,
  .svg-lipstick-watercolor__left,
  .svg-lipstick-watercolor__right {
    display: block;
    position: absolute;
  }
  .svg-eyelash__left {
    transform: rotateY(180deg);
    width: 300px;
    top: 48px;
    left: 82px;
  }
  .svg-eye-arrow__left {
    transform: rotateY(180deg);
    width: 300px;
    top: 65px;
    left: 130px;
    height: 110px;
  }
  .svg-arrows-shadow__left {
    transform: rotateY(180deg);
    width: 259px;
    top: 65px;
    left: 145px;
    height: 140px;
  }
  .svg-microblending__left {
    transform: rotateY(180deg);
    width: 300px;
    top: -11px;
    left: 105px;
  }
  .svg-brows-mixed__left {
    transform: rotateY(180deg);
    width: 300px;
    top: -10px;
    left: 115px;
  }
  .svg-brows-hair__left {
    transform: rotateY(180deg);
    width: 300px;
    top: -10px;
    left: 115px;
  }
  .svg-brows-correction__left {
    transform: rotateY(180deg);
    width: 300px;
    top: -10px;
    left: 115px;
  }
  .svg-lipstick__left {
    transform: rotateY(180deg);
    width: 300px;
    top: 50px;
    left: 115px;
  }
  .svg-lipstick-watercolor__left {
    transform: rotateY(180deg);
    width: 300px;
    top: 50px;
    left: 115px;
  }
  .svg-eyelash__right {
    top: 112px;
    right: -160px;
  }
  .svg-eye-arrow__right {
    top: 220px;
    right: -76px;
    height: 110px;
    width: 300px;
  }
  .svg-arrows-shadow__right {
    top: 239px;
    right: -76px;
    height: 93px;
    width: 312px;
  }
  .svg-microblending__right {
    top: 112px;
    right: -160px;
  }
  .svg-brows-mixed__right {
    top: 97px;
    right: -160px;
  }
  .svg-brows-hair__right {
    top: 70px;
    right: -160px;
  }
  .svg-brows-correction__right {
    top: 70px;
    right: -160px;
  }
  .svg-lipstick__right {
    top: 200px;
    right: -150px;
  }
  .svg-lipstick-watercolor__right {
    top: 200px;
    right: -150px;
  }
}
@media (min-width: 1200px) {
  .svg-eyelash__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 115px;
    top: 10px;
    left: -220px;
  }
  .svg-eye-arrow__left {
    transform: rotateY(0deg);
    width: 270px;
    height: 157px;
    top: 36px;
    left: -123px;
  }
  .svg-arrows-shadow__left {
    transform: rotateY(0deg);
    width: 300px;
    height: 157px;
    top: 66px;
    left: -147px;
  }
  .svg-microblending__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 61px;
    top: 10px;
    left: -220px;
  }
  .svg-brows-mixed__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 61px;
    top: 10px;
    left: -220px;
  }
  .svg-brows-hair__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 61px;
    top: 10px;
    left: -220px;
  }
  .svg-brows-correction__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 61px;
    top: 10px;
    left: -220px;
  }
  .svg-lipstick__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 61px;
    top: 90px;
    left: -220px;
  }
  .svg-lipstick-watercolor__left {
    transform: rotateY(0deg);
    width: fit-content;
    height: 61px;
    top: 70px;
    left: -220px;
  }
  .svg-eyelash__right {
    height: 200px;
    top: 123px;
    right: -155px;
  }
  .svg-eye-arrow__right {
    height: 133px;
    top: 240px;
    right: 28px;
    width: 201px;
  }
  .svg-arrows-shadow__right {
    height: 120px;
    top: 259px;
    right: 28px;
    width: 228px;
  }
  .svg-microblending__right {
    height: 178px;
    width: 230px;
    top: 143px;
    right: -155px;
  }
  .svg-brows-mixed__right {
    height: 219px;
    width: 230px;
    top: 102px;
    right: -155px;
  }
  .svg-brows-hair__right {
    height: 210px;
    width: 230px;
    top: 80px;
    right: -139px;
  }
  .svg-brows-correction__right {
    height: 197px;
    width: 230px;
    top: 96px;
    right: -139px;
  }
  .svg-lipstick__right {
    height: auto;
    width: 230px;
    top: 227px;
    right: -76px;
  }
  .svg-lipstick-watercolor__right {
    height: auto;
    width: 230px;
    top: 227px;
    right: -76px;
  }
}
@media (min-width: 1400px) {
  .svg-eyelash__left {
    height: auto;
    width: 240px;
    top: 111px;
    left: -80px;
  }
  .svg-eye-arrow__left {
    height: 165px;
    width: fit-content;
    top: 111px;
    left: -146px;
  }
  .svg-arrows-shadow__left {
    height: auto;
    width: 380px;
    top: 116px;
    left: -164px;
  }
  .svg-microblending__left {
    height: auto;
    width: 240px;
    top: 45px;
    left: -80px;
  }
  .svg-brows-mixed__left {
    height: auto;
    width: 251px;
    top: 45px;
    left: -65px;
  }
  .svg-brows-hair__left {
    height: auto;
    width: 251px;
    top: 45px;
    left: -65px;
  }
  .svg-brows-correction__left {
    height: auto;
    width: 251px;
    top: 45px;
    left: -65px;
  }
  .svg-lipstick__left {
    height: auto;
    width: fit-content;
    top: 132px;
    left: -159px;
  }
  .svg-lipstick-watercolor__left {
    height: auto;
    width: fit-content;
    top: 132px;
    left: -159px;
  }
  .svg-eyelash__right {
    height: 134px;
    width: 173px;
    top: 188px;
    right: -32px;
  }
  .svg-eye-arrow__right {
    height: 134px;
    width: 360px;
    top: 346px;
    right: -15px;
  }
  .svg-arrows-shadow__right {
    height: 110px;
    width: 384px;
    top: 373px;
    right: -15px;
  }
  .svg-microblending__right {
    height: 134px;
    width: 173px;
    top: 188px;
    right: -32px;
  }
  .svg-brows-mixed__right {
    height: 170px;
    width: 173px;
    top: 154px;
    right: -32px;
  }
  .svg-brows-hair__right {
    height: 170px;
    width: 173px;
    top: 118px;
    right: -32px;
  }
  .svg-brows-correction__right {
    height: 170px;
    width: 173px;
    top: 118px;
    right: -32px;
  }
  .svg-lipstick__right {
    height: 170px;
    width: 173px;
    top: 325px;
    right: -5px;
  }
  .svg-lipstick-watercolor__right {
    height: 170px;
    width: 173px;
    top: 325px;
    right: -5px;
  }
}
@media (min-width: 1700px) {
  .svg-eyelash__right {
    width: auto;
    height: auto;
    top: 225px;
    right: -52px;
  }
  .svg-eye-arrow__right {
    width: fit-content;
    height: auto;
    top: 420px;
    right: 35px;
  }
  .svg-arrows-shadow__right {
    width: fit-content;
    height: auto;
    top: 465px;
    right: 35px;
  }
  .svg-microblending__right {
    width: auto;
    height: auto;
    top: 225px;
    right: -52px;
  }
  .svg-brows-mixed__right {
    width: auto;
    height: auto;
    top: 188px;
    right: -52px;
  }
  .svg-brows-hair__right {
    width: auto;
    height: auto;
    top: 147px;
    right: -52px;
  }
  .svg-brows-correction__right {
    width: auto;
    height: auto;
    top: 147px;
    right: -52px;
  }
  .svg-lipstick__right {
    width: fit-content;
    height: auto;
    top: 400px;
    right: -52px;
  }
  .svg-lipstick-watercolor__right {
    width: fit-content;
    height: auto;
    top: 400px;
    right: -52px;
  }
  .svg-eyelash__left {
    width: auto;
    top: 130px;
    left: -140px;
  }
  .svg-eye-arrow__left {
    width: fit-content;
    height: auto;
    top: 146px;
    left: -140px;
  }
  .svg-arrows-shadow__left {
    width: fit-content;
    height: auto;
    top: 146px;
    left: -140px;
  }
  .svg-microblending__left {
    width: auto;
    top: 40px;
    left: -140px;
  }
  .svg-brows-mixed__left {
    width: 320px;
    top: 60px;
    left: -105px;
  }
  .svg-brows-hair__left {
    width: 320px;
    top: 60px;
    left: -105px;
  }
  .svg-brows-correction__left {
    width: 320px;
    top: 60px;
    left: -105px;
  }
  .svg-lipstick__left {
    width: fit-content;
    top: 202px;
    left: -152px;
  }
  .svg-lipstick-watercolor__left {
    width: fit-content;
    top: 168px;
    left: -152px;
  }
}
.icon--side-line {
  position: relative;
  display: inline-block;
}
.icon--side-line::after {
  content: "";
  position: absolute;
  top: 0;
  right: -12px;
  width: 1px;
  height: 100%;
  background: white;
}
.contacts__location {
  position: relative;
}
.contacts__location svg {
  position: absolute;
  left: -20px;
  top: 6px;
}
.img-stopper,
.img-stopper--changed,
.img-stopper--pos1 {
  min-width: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 44px;
  line-height: 1.49;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.title--pos-left {
  margin-left: 0;
}
@media (min-width: 1400px) {
  .title--d-none-lg {
    display: none;
  }
}
.title--d-block-lg {
  display: none;
}
@media (min-width: 1400px) {
  .title--d-block-lg {
    display: block;
  }
}
.title--bottom-line,
.title--bottom-line-left,
.title--bottom-line-left-low {
  position: relative;
}
.title--bottom-line:after,
.title--bottom-line-left:after,
.title--bottom-line-left-low:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background: #000;
}
.title--bottom-line:after {
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.title--bottom-line-left:after {
  bottom: -10px;
  left: 10px;
}
.title--bottom-line-left-low:after {
  bottom: -35px;
  left: 0;
}
.title--margin-side {
  margin-left: 15px;
  margin-right: 15px;
}
.title--margin {
  margin-top: 80px;
  margin-bottom: 60px;
}
.title--contacts-margin {
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  .title {
    font-size: 48px;
  }
  .title--margin-side {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 52px;
    padding-left: 0;
    padding-right: 0;
  }
  .title--d-none {
    display: none;
  }
  .title--margin {
    margin-top: 115px;
    margin-bottom: 80px;
  }
  .title--margin-side {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .title--margin {
    margin-top: 215px;
    margin-bottom: 80px;
  }
}
.title--header,
.title--header-service {
  position: relative;
  font-family: 'Circe-Bold';
  font-size: 42px;
  line-height: 1;
  color: #FFFFFF;
  margin-bottom: 0.6em;
  z-index: 1;
  transform: translateY(120%);
  opacity: 0;
  transition: all 0.8s ease;
}
.title--header-service {
  line-height: 1;
}
.title--header._active,
.title--header-service._active {
  transform: translateY(0);
  opacity: 1;
}
.title__description {
  font-family: 'Circe-Light';
  font-size: 21px;
  line-height: 24px;
  color: #FFFFFF;
  transform: translateY(120%);
  opacity: 0;
  transition: all 0.8s ease;
}
.title__description._active {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.8s ease 0.2s;
}
.subtitle {
  font-family: 'Circe-Bold';
  font-size: 30px;
  line-height: 1.5;
  color: #000000;
}
.subtitle--small {
  font-size: 25px;
  line-height: 1.2;
}
.subtitle--changed {
  font-size: 30px;
  line-height: 1.3;
}
.subtitle--colored {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0.6em 1.87em;
  background: #000;
  width: fit-content;
}
@media (min-width: 576px) {
  .title--header {
    font-size: 55px;
    line-height: 55px;
  }
  .title__description {
    font-size: 26px;
    line-height: 30px;
  }
  .title--header-service {
    font-size: 57px;
  }
  .subtitle {
    font-size: 32px;
  }
}
@media (min-width: 768px) {
  .subtitle--changed {
    font-size: 35px;
    line-height: 1.5;
  }
  .subtitle {
    font-size: 35px;
  }
  .subtitle--small {
    font-size: 25px;
  }
  .title--header-service {
    line-height: 1.2;
  }
}
@media (min-width: 992px) {
  .title--header {
    font-size: 60px;
    line-height: 48px;
  }
  .title__description {
    font-size: 32px;
    line-height: 38px;
  }
  .title--header-service {
    font-size: 48px;
  }
}
@media (min-width: 1150px) {
  .title--header {
    font-size: 64px;
    line-height: 50px;
  }
  .title__description {
    font-size: 30px;
    line-height: 34px;
  }
}
@media (min-width: 1260px) {
  .title--header {
    font-size: 72px;
    line-height: 60px;
  }
  .title--header-service {
    font-size: 62px;
  }
  .title__description {
    font-size: 30px;
    line-height: 36px;
  }
}
@media (min-width: 1700px) {
  .title__description {
    font-size: 35px;
    line-height: 40px;
  }
}
@media (min-width: 1400px) {
  .title--header-service {
    font-size: 72px;
  }
}
.logo--d-none {
  display: none;
}
@media (min-width: 992px) {
  .logo--d-none {
    display: block;
  }
}
.btn {
  position: relative;
  display: block;
  font-family: 'Circe-Regular';
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  transition: all 0.4s ease-out;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}
.btn__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.btn__wrapper--row,
.btn__wrapper--insta {
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.btn__wrapper--margin {
  margin-top: 15px;
}
.btn__wrapper--margin-small {
  margin-top: 20px;
}
.btn__wrapper--margin-bigger {
  margin-top: 10px;
}
.btn__wrapper .btn:last-child {
  margin-top: 20px;
}
.btn__wrapper--changed,
.btn__wrapper--full {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.btn__wrapper--changed .btn:last-child,
.btn__wrapper--full .btn:last-child {
  margin-top: 20px;
}
.btn__wrapper--mobile {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .btn__wrapper {
    width: 70%;
  }
  .btn__wrapper--changed {
    width: fit-content;
  }
  .btn__wrapper--mobile {
    display: none;
  }
  .btn__wrapper--full {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .btn__wrapper {
    width: 47%;
  }
  .btn__wrapper--row {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .btn__wrapper {
    width: auto;
  }
  .btn__wrapper--insta {
    flex-direction: row;
  }
  .btn__wrapper--insta button:first-child {
    margin-right: 20px;
  }
}
@media (min-width: 1400px) {
  .btn__wrapper--row {
    flex-direction: row;
  }
  .btn__wrapper--row button:first-child {
    margin-right: 20px;
  }
}
@media (min-width: 1500px) {
  .btn__wrapper--margin {
    margin-top: 40px;
  }
}
@media (min-width: 1600px) {
  .btn__wrapper--margin-bigger {
    margin-top: 30px;
  }
}
.btn--center {
  margin-left: auto;
  margin-right: auto;
}
.btn--size {
  width: auto;
  padding: 0.71em 2.5em;
}
@media (min-width: 992px) {
  .btn--size {
    width: fit-content;
  }
}
.btn--size-header {
  width: auto;
  padding: 0.71em 2.5em;
}
@media (min-width: 1400px) {
  .btn--size-header {
    width: fit-content;
  }
}
.btn--size-narrow {
  width: 94%;
  padding: 0.71em 2.5em;
}
.btn--size-form {
  width: 100%;
  padding: 0.71em 2.5em;
}
.btn--size-small {
  font-size: 11px;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .btn--size-small {
    font-size: 14px;
    line-height: 21px;
  }
}
.btn--light {
  color: #000000;
  background: #ffffff;
  border: 1px solid #000000;
}
.btn--light:hover,
.btn--light:focus,
.btn--light:active {
  outline: none;
  background: #000000;
  border-style: outset;
  color: #ffffff;
}
.btn--dark {
  color: #ffffff;
  background: #000000;
  border: 1px solid #ffffff;
}
.btn--dark:hover,
.btn--dark:focus,
.btn--dark:active {
  outline: #000000 solid 1px;
  border-style: outset;
}
.btn--mt {
  margin-top: 40px;
}
.btn--mt20 {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .btn--d-none {
    display: none;
  }
}
@media (min-width: 576px) {
  .btn-d-none-small {
    display: none;
  }
}
.text {
  position: relative;
  font-family: 'Circe-Regular';
}
.text--standart {
  font-size: 16px;
  line-height: 1.5;
}
.text--bold {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 21px;
  line-height: 1.2;
}
.text--light {
  font-family: 'Circe-Light';
  font-weight: 300;
  line-height: 1.5;
}
.text--emphasize {
  font-size: 21px;
  line-height: 31px;
}
.text--dark {
  color: #000000;
}
.text--bigger {
  font-size: 17px;
}
.text--big {
  font-size: 22px;
}
.text--lh {
  line-height: 1.7;
}
.text--bottom-line {
  position: relative;
}
.text--bottom-line::after {
  content: "";
  position: absolute;
  bottom: -1.25em;
  left: 0;
  width: 32px;
  height: 1.5px;
  background: #000000;
}
.text--huge {
  font-family: 'Circe-Bold';
  font-weight: 700;
  font-size: 60px;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .text--bigger {
    font-size: 19px;
  }
}
@media (min-width: 768px) {
  .text--bigger {
    font-size: 21px;
  }
  .text--emphasize {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  .text--huge {
    font-size: 72px;
  }
}
@media (min-width: 1500px) {
  .text--big {
    font-size: 22px;
  }
}
@media (min-width: 1800px) {
  .text--big {
    font-size: 25px;
  }
}
.text__box--width {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .text__box--width {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .text__box--width {
    padding-left: 0;
  }
}
@media (min-width: 1300px) {
  .text__box--width {
    width: 80%;
  }
}
@media (min-width: 1700px) {
  .text__box--width {
    width: 60%;
  }
}
.text--box-position {
  margin-left: auto;
  margin-right: auto;
}
.text--box-width {
  width: 96%;
}
.text--align-changed {
  text-align: center;
}
@media (min-width: 576px) {
  .text--box-width {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .text--box-width {
    width: 90%;
  }
  .text--box-position {
    margin-left: 0;
    margin-right: auto;
  }
  .text--align-changed {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .text--box-width {
    width: 70%;
  }
  .text--box-position {
    margin-left: auto;
    margin-right: auto;
  }
  .text--align-changed {
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .text--box-width {
    width: 60%;
  }
}
@media (min-width: 1600px) {
  .text--box-width {
    width: 50%;
  }
}
.rating-result span {
  padding: 0;
  font-size: 19px;
  margin: 0 2px;
  line-height: 1;
  color: lightgrey;
}
.rating-result > span:before {
  content: '★';
}
.rating-result > span.active {
  color: #000;
}
.list {
  list-style-type: none;
  padding-left: 0;
}
.list--flex {
  display: flex;
  flex-wrap: wrap;
}
.list--bottom-line {
  border-bottom: 1px solid #000000;
}
.list--advantages {
  border-bottom: 1px solid #000000;
  padding-left: 30px;
}
.list--advantages--margin {
  margin-top: 100px;
}
.list--advantages li {
  width: 100%;
  padding-right: 30px;
  padding-bottom: 50px;
}
.list--advantages img {
  width: 50px;
  height: 55px;
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .list--advantages {
    padding-left: 40px;
  }
  .list--advantages li {
    width: 50%;
    padding-right: 40px;
  }
}
@media (min-width: 768px) {
  .list--advantages li {
    width: 43%;
  }
  .list--advantages img {
    width: 60px;
    height: 65px;
  }
}
@media (min-width: 992px) {
  .list--advantages {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .list--advantages li {
    width: 33%;
  }
}
@media (min-width: 1400px) {
  .list--advantages li {
    width: 30%;
  }
}
@media (min-width: 1500px) {
  .list--advantages {
    border-bottom: none;
  }
  .list--advantages li {
    width: 25%;
  }
  .list--advantages li:nth-child(n+5) {
    border-bottom: 1px solid #000000;
  }
}
@media (min-width: 1600px) {
  .list--advantages li {
    width: 22%;
  }
}
.list--line {
  flex-direction: column;
  margin-left: 30px;
}
.list--font-bigger {
  padding-left: 30px;
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .list--font-bigger {
    font-size: 19px;
  }
}
@media (min-width: 768px) {
  .list--font-bigger {
    font-size: 21px;
  }
}
.list--font-light {
  padding-left: 30px;
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.8;
}
@media (min-width: 576px) {
  .list--font-light {
    font-size: 19px;
  }
}
@media (min-width: 768px) {
  .list--font-light {
    font-size: 21px;
  }
}
.list--margin {
  margin-top: 40px;
}
.list__item {
  position: relative;
}
.list__item--margin:not(:first-child) {
  margin-top: 20px;
}
.list__item--margin1:not(:last-child) {
  margin-right: 80px;
}
.list__item--margin1:last-child {
  margin-right: 30px;
}
.list__item--margin2:not(:first-child) {
  margin-top: 15px;
}
.list__item:before {
  content: "";
  position: absolute;
  left: -30px;
  width: 14px;
  height: 100%;
}
.list__item--check:before {
  background: url(../img/icons/check.svg) no-repeat center;
  background-size: contain;
}
.list__item--cross:before {
  background: url(../img/icons/cross.svg) no-repeat;
  background-size: contain;
}
.list--service-descr {
  padding-left: 30px;
  font-family: 'Circe-Light';
  font-weight: 300;
  font-size: 18px;
  line-height: 2.16;
}
.list--service-descr .list__item {
  position: relative;
}
.list--service-descr .list__item:before {
  content: "";
  position: absolute;
  left: -30px;
  width: 14px;
  height: 100%;
  background: url(../img/icons/check.svg) no-repeat center;
  background-size: contain;
}
.list--materials-descr {
  padding-left: 30px;
  font-family: 'Circe-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
}
.list--materials-descr .list__item {
  position: relative;
}
.list--materials-descr .list__item--flex-item {
  width: 100%;
}
.list--materials-descr .list__item:before {
  content: "";
  position: absolute;
  left: -30px;
  width: 14px;
  height: 100%;
}
.list--materials-descr .list__item--check:before {
  background: url(../img/icons/check.svg) no-repeat center;
  background-size: contain;
}
.list--materials-descr .list__item--cross:before {
  background: url(../img/icons/cross.svg) no-repeat center;
  background-size: contain;
}
@media (min-width: 576px) {
  .list--materials-descr .list__item--flex-item {
    width: 50%;
  }
}
@media (min-width: 576px) {
  .list--height {
    height: 4em;
  }
  .list--service-descr {
    font-size: 22px;
  }
  .list .list__item:before {
    width: 16px;
  }
}
@media (min-width: 992px) {
  .list--line {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .list--line {
    flex-direction: row;
  }
}
@media (min-width: 1500px) {
  .list--bottom-line {
    border-bottom: 1px solid #000000;
  }
}
@media (min-width: 1700px) {
  .list--service-descr {
    font-size: 24px;
  }
}
.list-services .list__item:not(:last-child) {
  margin-bottom: 20px;
}
.list-services .list__item {
  font-size: 17px;
}
.list-services .list__item a {
  color: inherit;
}
@media (min-width: 768px) {
  .list-services .list__item {
    font-size: 19px;
  }
}
@media (min-width: 992px) {
  .list-services .list__item {
    font-size: 20px;
  }
}
@media (min-width: 1300px) {
  .list-services .list__item {
    font-size: 21px;
  }
}
.link {
  position: relative;
  display: inline-block;
  color: #000000;
}
.link--pad {
  padding-left: 15px;
}
.link::after {
  content: "";
  position: absolute;
}
.link--arrow-size.link::after {
  width: 45px;
  height: 100%;
}
.link--arrow-right {
  padding-right: 60px;
}
.link--arrow-right.link::after {
  background: url('../img/icons/arrow-link.svg') no-repeat;
  background-size: contain;
  background-position: 50%  50%;
  top: 0;
  right: 8px;
}
.link--margin {
  margin-top: 45px;
}
@media (min-width: 768px) {
  .link--arrow-size.link::after {
    width: 65px;
  }
  .link--arrow-right {
    padding-right: 80px;
  }
}
@media (min-width: 992px) {
  .link--pad {
    padding-left: 0;
  }
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
body {
  position: relative;
  font-size: 16px;
  min-width: 320px;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
}
.d-flex {
  display: flex;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
.br-d-none {
  display: none;
}
@media (min-width: 1150px) {
  .br-d-none {
    display: block;
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 56px;
  height: 56px;
  background: #000;
  font-size: 0;
  border: none;
  z-index: 1;
  outline-color: grey;
}
.slick-arrow:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/icons/arrow.svg) no-repeat center;
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
}
.slick-next:after {
  transform: rotateY(180deg);
}
.section-wrapper--insta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.section-wrapper--masters {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (min-width: 576px) {
  .section-wrapper--masters {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  .section-wrapper--masters {
    width: 70%;
  }
}
@media (min-width: 1700px) {
  .section-wrapper--masters {
    width: 65%;
  }
}
.br-d-none-mobile {
  display: none;
}
@media (min-width: 576px) {
  .br-d-none-mobile {
    display: block;
  }
}
.br-d-none-lg {
  display: none;
}
@media (min-width: 1200px) {
  .br-d-none-lg {
    display: block;
  }
}
.section--margin {
  margin-top: 130px;
}
.section--pad {
  padding-right: 30px;
  padding-left: 30px;
}
@media (min-width: 992px) {
  .section--pad {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .section--margin {
    margin-top: 230px;
  }
}
@media (min-width: 1500px) {
  .section--pad {
    padding-right: 0;
  }
}
.section__wrapper--margin {
  margin-top: 60px;
}
.section__wrapper--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1200px) {
  .section__wrapper--margin {
    margin-top: 90px;
  }
}
@media (min-width: 1500px) {
  .section__wrapper {
    justify-content: flex-start;
  }
}
