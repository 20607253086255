.insta__wrapper {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

    &--margin {
        margin-top: 70px;
    }

    &--padding {
        padding-bottom: 85px;
    }

    &--line {
        border-bottom: 1px solid #000000;
    }

    &--pos-left {
        margin-left: 0;
    }

    @media(min-width: 768px) {
        width: 80%;
    }
}
.insta-card {
    position: relative;
    width: 32%;

    &:nth-child(n+4) {
        margin-top: 15px;
    }

    .aspect-ratio-box {
        overflow: hidden;
        padding-top: 348px / 380px * 100%;
        position: relative;
    }

    .aspect-ratio-box-inside {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media(min-width: 1400px) {
        &:nth-child(n+4) {
            margin-top: 25px;
        }
    }

}