// Base
@import "../less/base/_fonts.less";
@import "../less/base/_variables.less";
@import "../less/base/_margins.less";

// sections
@import "../less/sections/_content-container.less";
@import "../less/sections/_header.less";
@import "../less/sections/_sertificate.less";
@import "../less/sections/_comments.less";
@import "../less/sections/_insta.less";
@import "../less/sections/_address.less";
@import "../less/sections/_nav-helper.less";
@import "../less/sections/_questions.less";
@import "../less/sections/_makeup.less";
@import "../less/sections/_section.less";
@import "../less/sections/_modal.less";

// Blocks
@import "../less/blocks/_nav.less";
@import "../less/blocks/_contacts.less";
@import "../less/blocks/_block-about.less";
@import "../less/blocks/_service-card.less";
@import "../less/blocks/_masters.less";
@import "../less/blocks/_proposals.less";
@import "../less/blocks/_form.less";
@import "../less/blocks/_procedure.less";
@import "../less/blocks/_stages.less";
@import "../less/blocks/_specialists.less";
@import "../less/blocks/_card-service.less";
@import "../less/blocks/_block.less";
@import "../less/blocks/_card.less";
@import "../less/blocks/_card-advantage.less";

// Elements
@import "../less/elements/_svg.less";
@import "../less/elements/_icon.less";
@import "../less/elements/_img.less";
@import "../less/elements/_title.less";
@import "../less/elements/_logo.less";
@import "../less/elements/_btn.less";
@import "../less/elements/_text.less";
@import "../less/elements/_rating.less";
@import "../less/elements/_list.less";
@import "../less/elements/_link.less";


* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

body {
    position: relative;
    font-size: 16px;
    min-width: 320px;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, p {
    margin: 0;
}

.d-flex {
    display: flex;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

.br-d-none {
    display: none;

    @media(min-width: 1150px) {
        display: block;
    }
}

.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 56px;
    height: 56px;
    background: #000;
    font-size: 0;
    border: none;
    z-index: 1;
    outline-color: grey;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../img/icons/arrow.svg) no-repeat center;
    }
}
.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
    &:after {
        transform: rotateY(180deg);
    }
}

.section-wrapper--insta {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;
}

.section-wrapper--masters {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @media(min-width: 576px) {
        width: 80%;
    }

    @media(min-width: 1600px) {
        width: 70%;
    }

    @media(min-width: 1700px) {
        width: 65%;
    }
}


.br-d-none-mobile {
    display: none;

    @media(min-width: 576px) {
        display: block;
    }
}

.br-d-none-lg {
    display: none;

    @media(min-width: 1200px) {
        display: block;
    }
}

.section {

    &--margin {
        margin-top: 130px;
    }

    &--pad {
        padding-right: 30px;
        padding-left: 30px;
    }

    @media(min-width: 768px) {
        
    }

    @media(min-width: 992px) {
        &--pad {
            padding-left: 0;
        }
    }

    @media(min-width: 1200px) {
        &--margin {
            margin-top: 230px;
        }
    }

    @media(min-width: 1500px) {
        &--pad {
            padding-right: 0;
        }
    }
}

.section__wrapper {

    &--margin {
        margin-top: 60px;
    }

    &--flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media(min-width: 1200px) {
        &--margin {
            margin-top: 90px;
        }
    }

    @media(min-width: 1500px) {
        justify-content: flex-start;

    }
}