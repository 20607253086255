.block {

    &--flex {
        display: flex;
        flex-direction: column;
    }

    &--size {
        width: 100%;
    }

    &--margin {
        margin-top: 140px;
    }

    &--margin-smaller {
        margin-top: 130px;
    }

    &--pad-bottom {
        padding-bottom: 40px;
    }

    &--bottom-line {
        border-bottom: 1px solid #000000;
    }

    &--width {
        width: 96%;
    }

    &--pad-left {
        padding-left: 30px;
    }

    @media(min-width: 576px) {
        &--width {
            width: 90%;
        }
    }

    @media(min-width: 992px) {
        &--pad-left {
            padding-left: 0;
        }
    }

    @media(min-width: 1200px) {
        &--width {
            width: 80%;
        }
    }

    @media(min-width: 1500px) {
        &--size {
            width: 100%;
        }

        &--flex {
            flex-direction: row;
        }

        &--margin {
            margin-top: 240px;
        }

        &--pad-bottom {
            padding-bottom: 100px;
        }

        &--width {
            width: 60%;
        }
    } 
}

.block__img {

    &--size,
    &--size-narrow,
    &--size-bigger,
    &--size-medium {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        height: fit-content;
    }

    &--size,
    &--size-medium,
    &--size-bigger,
    &--size-narrow {
        width: 100%;        
    }

    &--flex-order {
        order: 0;
    }

    @media(min-width: 480px) {
        &--size-narrow,
        &--size-medium {
            width: 80%;
        }
    }

    @media(min-width: 576px) {
        &--size {
            width: 85%;
        }
        &--size-narrow,
        &--size-medium {
            width: 70%;
        }

        &--size-bigger {
            width: 80%;        
        }
    }

    @media(min-width: 768px) {
        &--size {
            width: 65%;
        }

        &--size-narrow,
        &--size-medium {
            width: 60%;
        }
    }

    @media(min-width: 992px) {
        &--size {
            width: 70%;
        }

        &--size-narrow {
            width: 55%;
        }

        &--size-medium {
            width: 60%;        
        }
    }

    @media(min-width: 1200px) {
        &--size {
            width: 50%;
        }

        &--size-medium {
            width: 50%;        
        }

        &--size-narrow {
            width: 45%;
        }

        &--size-bigger {
            width: 60%;        
        }
    }

    @media(min-width: 1500px) {
        &--size {
            width: 45%;
        }

        &--size-medium {
            width: 37%;        
        }

        &--size-narrow {
            width: 32%;
        }

        &--flex-order {
            order: 1;
        }

        &--size-bigger {
            width: 47%;        
        }
    }

    @media(min-width: 1700px) {
        &--size-narrow {
            width: 26%;
        }
    }
}

.block__text {

    &--size,
    &--size1,
    &--size-changed,
    &--size-smaller {
        width: 100%;
    }

    &--pad,
    &--pad-left {        
        padding: 20px 30px 40px 30px;
    }

    &--pad1 {        
        padding: 60px 15px 40px 15px;
    }

    &--pad2 {        
        padding: 60px 15px;
    }


    &--flex-order {
        order: 1;
    }

    @media(min-width: 576px) {
        &--pad1 {        
            padding: 60px 40px 40px 30px;
        }

        &--pad2 {        
            padding: 70px 30px 60px 30px;
        }
    }

    @media(min-width: 768px) {
        &--size-changed {
            width: 65%;
        }

        &--pad2 {        
            padding: 70px 150px 60px 30px;
        }
    }

    @media(min-width: 992px) {
        &--pad,
        &--pad-left {
            padding: 20px 40px 40px 0;
        }

        &--pad1 {        
            padding: 60px 40px 40px 0;
        }

        &--size-changed {
            width: 100%;
        }

        &--size1 {
            width: 80%;
        }

        &--pad2 {        
            padding: 60px 30px 30px 0;
        }
    }

    @media(min-width: 1200px) {
        &--size-changed {
            width: 60%;
        }
        &--size1 {
            width: 50%;
        }
    }

    @media(min-width: 1500px) {
        &--pad {
            padding: 40px 40px 40px 90px;
        }

        &--pad1 {        
            padding: 60px 40px 40px 70px;
        }

        &--pad2 {        
            padding: 20px 60px 30px 50px;
        }

        &--size {
            width: 55%;
        }

        &--size1 {
            width: 63%;
        }

        &--size-changed {
            width: 55%;
        }

        &--flex-order {
            order: 0;
        }

        &--size-smaller {
            width: 43%;
        }
    }

    @media(min-width: 1600px) {
        &--size-changed {
            width: 45%;
        }

        &--pad2 {        
            padding: 30px 80px 30px 50px;
        }
    }

    @media(min-width: 1700px) {
        &--size-smaller {
            width: 32%;
        }

        &--size1 {
            width: 45%;
        }

        &--pad2 {        
            padding: 60px 15px 60px 50px;
        }
    }

    @media(min-width: 1800px) {
        &--pad1 {        
            padding: 110px 40px 40px 90px;
        }

        &--pad2 {        
            padding: 90px 35px 60px 50px;
        }
    }
}

.block-inner {
    position: relative;
    background: inherit;
    overflow: hidden;
    text-align: center;

    h3, p {
        position: relative;
    }

    &--pad {
        padding: 35px 30px;
    }

    &__bg {
        position: absolute;
        width: 110%;
        height: 110%;
        top: -5%;
        left: -5%;
        // width: 100%;
        // height: 100%;
        // top: 0;
        // left: 0;
        // background-color: rgba(255, 255, 255, 0.3);
        // backdrop-filter: blur(22px) saturate(100%) contrast(45%) brightness(140%);
        filter: blur(10px) saturate(100%) contrast(20%) brightness(160%);
        background: inherit;
    }

    &--size {
        width: 100%
    }

    &--position {
        margin-left: auto;
        margin-right: auto;
    }

    @media(min-width: 576px) {
        &--pad {
            padding: 45px 100px;
        }
    
    }

    @media(min-width: 768px) {
        &--size {
            width: 550px;
        }

        &--pad {
            padding: 45px 100px;
        }
    
    }

    @media(min-width: 992px) {
        &--size {
            width: 480px;
        }

        &--pad {
            padding: 45px 80px;
        }
    
    }

    @media(min-width: 1200px) {
        &--size {
            width: 550px;
        }

        &--pad {
            padding: 45px 100px;
        }
    
    }
}