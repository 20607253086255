.modal {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;

    &.open {
        opacity: 1;
        visibility: visible;
    }
}

.modal__body {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__content {
    position: relative;
    max-width: 94%;
    max-height: 94vh;
    background: #F9F9F9;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    @media(min-width: 576px) {
        max-width: 510px;
        max-height: 90vh;
    }

    @media(min-width: 768px) {
        max-width: 710px;
    }

    @media(min-width: 992px) {
        max-width: 930px;
    }

    @media(min-width: 1200px) {
        max-width: 1100px;
    }

    @media(min-width: 1500px) {
        max-width: 1400px;
    }
}

.modal__photo {
    display: none;
    width: 30%;
    position: relative;

    .aspect-ratio-box {
        overflow: hidden;
        padding-top: 412px / 490px * 100%;
        position: relative;
        height: 100%;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }

    @media(min-width: 992px) {
        display: block;
    }
}

.modal__description {
    width: 100%;
    overflow-y: auto;

    &--pad {
        padding: 30px;
    }

    @media(min-width: 576px) {
        &--pad {
            padding: 30px 45px 30px 30px;
        }
    }

    @media(min-width: 992px) {
        width: 70%;
    }

    @media(min-width: 1200px) {
        &--pad {
            padding: 35px 70px 35px 35px;
        }
    }

    @media(min-width: 1500px) {
        &--pad {
            padding: 55px 90px 55px 55px;
        }
    }
}

.modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid black;
    cursor: pointer;
    opacity: 0.6;

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 92%;
        background: #000;
        left: 50%;
        top: 4%;
    }
    &:before {
        transform: rotate(45deg) translateX(-50%);
    }

    &:after {
        transform: rotate(-45deg) translateX(-50%);
    }

    &:hover,
    &:focus, 
    &:active {
        opacity: 1;
    }

    @media(min-width: 576px) {
        top: 15px;
        right: 15px;
        width: 25px;
        height: 25px;
    }
}

.modal__title {
    font-family: 'Circe-Bold';
    font-weight: 700;
    font-size: 28px;
    line-height: 1.5;

    @media(min-width: 1200px){
        font-size: 32px;
    }

    @media(min-width: 1500px){
        font-size: 35px;
    }
}

p.modal__text {
    font-size: 14px;

    @media(min-width: 768px) {
        font-size: inherit;
    }

}

.modal__button {
    display: flex;
    flex-direction: column;

    button:first-child {
        margin-bottom: 15px;
    }

    button {
        font-size: 12px;
    }

    @media(min-width: 768px){
        flex-direction: row;

        button {
            font-size: 13px;
        }

        button:first-child {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    @media(min-width: 992px) {
        button {
            font-size: 14px;
        }
    }

}